import React from 'react';
import Topbar from './components/Topbar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import RegistrationCard from './components/RegistrationCard';
import img from '../../../../assets/images/registration.jpg';
import SharedBanner from '../../../../shared/SharedBanner';
import AnimBtn from '../../../../shared/button/AnimBtn';
import Grid from '@material-ui/core/Grid';
import Image from '../../../../shared/Image';
import Link from 'react-router-dom/Link';
import banner from '../../../../assets/images/careers/careers.jpg';
import career1 from '../../../../assets/images/careers/banner.webp';
import career2 from '../../../../assets/images/careers/career2.webp';
import { slideInRight } from '../../../../styles/animations.module.scss';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(({ breakpoints: { down, up } }) => ({
  root: {
    background: '#fff',
    paddingBottom: '3rem'
  },
  banner: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    color: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    margin: '0 auto',
    [down('sm')]: {
      width: '95%',
      margin: '.2rem auto'
    },
    '& h6': {
      textAlign: 'justify',
      margin: '1rem 0',
      fontSize: '1.1rem !important',
      fontWeight: 'lighter',
      [down('sm')]: {
        width: '95%',
      },
    },
    '& h4': {
      fontWeight: 'bold',
      margin: '.5rem 0'
    },
    '& button': {
      margin: '.5rem 0'
    }
  },
  text: {
    margin: '1rem 0 2rem 0',
    textAlign: 'justify'
  },
  wrapper: {
    padding: '2rem 7rem 4rem 7rem',
    [down('sm')]: {
      padding: '2rem'
    },
    '& > p': {
      fontSize: '18px !important',
      margin: '1rem 0',
      color: '#000 !important'
    }
  },
  img: {
    borderRadius: 10,
    height: 200,
    objectFit: 'cover',
    width: '50%',
    [down('sm')]: {
      width: '100%',
    }
  },
  section: {
    display: 'flex',
    flexDirection: 'column'
  },
  leftImg: {
    transform: 'translateX(16rem) translateY(-130px)',

    width: '50%',
    height: 200,
    objectFit: 'cover',
    '& img': {
      borderRadius: 10,
    },
    '& button': {
      display: 'flex',
      flexDirection: 'column',
      margin: '2rem auto'
    },
    [up('xl')]: {
      transform: 'translateX(460px) translateY(-130px)',
    },
    [down('sm')]: {
      transform: 'translateX(0)',
      width: '100%',
    }
  },
  btn: {
    background: 'linear-gradient(0deg, #72BFAD, #72BFAD), #72BFAD',
    borderRadius: 2,
    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24)',
    padding: '.5rem 1.5rem',
    fontWeight: 'bold',
    margin: '0 .5rem',
    position: 'relative',
    top: -10
  },
  link: {
    textDecoration: 'none',
    color: '#111'
  },
  content: {
    margin: '2.5rem 0',
    textAlign: 'justify'
  },
  container: {
    padding: '0rem 7rem',
    [down('sm')]: {
      padding: '0 2rem'
    }
  },
  paper: {
    height: '100%',
    padding: '1.5rem'
  }
}));

const Registration = () => {
  const classes = useStyles();
  return (
    <section className={classes.root}>
      <SharedBanner
        Content={
          <section className={classes.banner}>
            <Typography variant="h4" color="initial" className={classes.text}>Careers</Typography>
            <Typography variant="subtitle1" color="initial" className={classes.text} id='sfProLight'>
              Expand Your Horizons...
          </Typography>
            {/* <AnimBtn size='small'>
              Get Started
          </AnimBtn> */}
          </section>
        }
        img={banner}
      />

      <Grid container spacing={4} className={classes.wrapper}>
        <Grid item lg={6} md={6} sm={12} xs={12}>
        <section className={classes.section}>
        <Typography variant="body1" color="initial" className={classes.text} id='sfProLight'>At 5C, the word career means much more than a job. We understand the paramount need for our people to grow, and we make sure that this growth occurs. For recruitments within 5C or vacancies in our client companies, people are our most important assets and we ensure a conducive work environment with favorable conditions to enable their growth processes.</Typography>
            <Image image={{
              // height: '50%',
              // width: '100%',
              src: career1,
              className: classes.img
            }} />
            <section className={classes.leftImg}>
              <Image image={{
                height: '100%',
                // width: '50%',
                src: career2,
                // className: 
              }} />
            </section>

          </section>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
        <Typography variant="body1" color="initial" className={classes.text}>To be able to view available jobs on 5C and also apply for these jobs, you need to create an account and log in.</Typography>
        <Paper variant='outlined' className={[slideInRight, classes.paper]}>
        <RegistrationCard />
          </Paper>
          
        </Grid>
      </Grid>

      {/* <section className={classes.container}>
        <Typography variant="body1" color="initial" className={classes.content}>We offer a wide scope of opportunities in several professional fields ranging from Human Resources, Marketing and Business Development, Sales, Customer Relations, Finance, Legal & Compliance, Contracts & Procurement, Logistics & Facilities Management, to Information and communication technology and Business Management etc. So yes, there’s definitely a role you can play!</Typography>
        <Typography variant="body1" color="initial" className={classes.content}>As part of the 5C workforce, you get the full 5C experience – where we LIVE IT by Learning, Innovating, creating Values, Excelling, showing Integrity, and prizing Teamwork.</Typography>
        <Typography variant="body1" color="initial" className={classes.content}>We recognize your potentials – the world should too. Expand your horizon, start here
        <Link to='/portal/login' className={classes.link}>
            <span className={classes.btn}>Get Started</span>
          </Link></Typography>
      </section> */}
    </section>
  );
};

export default Registration;