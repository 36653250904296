import { combineReducers } from 'redux';
import { educationalInfoReducer } from './educationInfoReducer';
import { reloadReducer } from './reloadReducer';
import { workExpReducer } from './workExpReducer';
import { userProfileReducer } from './userProfileReducer';
import { jobsReducers } from './jobsReducer';
import { favoriteReducer, favouriteReducer } from './favouriteReducer';
import { jobDetailsReducer } from './jobDetailsReducer';
import { historyReducer } from './historyReducer';
import { appliedJobsReducer } from './appliedJobReducer';
// admin
import { adminProfile } from './adminReducers/adminProfileReducer';
import profileSlice from '../components/slice/profileSlice';
import applicantSlice from '../components/portal/admin/bookmark-list/applicantSlice';
import jobDetailsSlice from '../components/portal/job/jobDetailsSlice';

const allReducers = {
  educationalInfo: educationalInfoReducer,
  Reload: reloadReducer,
  workExperience: workExpReducer,
  userProfile: userProfileReducer,
  jobList: jobsReducers,
  favouriteJobs: favouriteReducer,
  jobDetails: jobDetailsReducer,
  historyState: historyReducer,
  appliedJobs: appliedJobsReducer,
  adminProfile: adminProfile,
  profile: profileSlice,
  applicant: applicantSlice,
  job: jobDetailsSlice,
}


export const reducer = combineReducers(allReducers);
