import React from 'react';
import empty from '../assets/svgs/empty.svg';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  svg: {
    width: '80%',
    height: '80%',
    margin: '0 auto',
    '& img': {
      width: '100%',
      height: '100%'
    },
    '& p': {
      textAlign: 'center',
      fontWeight: 'bold'
    }
  }
}))

const EmptyComponent = ({ title = 'No Data'}) => {
  const classes = useStyles();
  return (
    <div className={classes.svg}>
      <img src={empty} alt='empty_svg'/>
      <p>{title}</p>
    </div>
  );
};

export default EmptyComponent;