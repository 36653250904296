/* eslint-disable react-hooks/exhaustive-deps */
/**
 * /* eslint-disable react-hooks/exhaustive-deps
 *
 * @format
 */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react';
// import AdminNavBar from '../../navigation/adminNav';
import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';
import ApplicantCard from './applicantCard';
import noApplicant from '../../../../assets/svgs/applicants.svg';
import { useParams } from 'react-router';

const useStyles = makeStyles(theme => ({
	wrapper: {
		width: '95%',
		margin: '0 auto',
		[theme.breakpoints.up('md')]: {
			width: '99%'
		}
	},
	details: {
		width: '70%',
		display: 'flex',
		flexDirection: 'column',
		height: 150,
		overflowY: 'hidden',
		transition: 'height .5s',
		[theme.breakpoints.down('md')]: {
			height: 270,
			width: '100%',
		},
		[theme.breakpoints.down('xs')]: {
			height: 250,
			width: '100%',
		}
	},
	collaps: {
		height: 420,
		transition: 'height .5s',
		[theme.breakpoints.down('md')]: {
			height: 700
		},
		[theme.breakpoints.down('xs')]: {
			height: 700
		}
	},
	info: {
		'& span': {
			margin: '0 .5rem',
			fontWeight: 'bolder'
		},
		[theme.breakpoints.down('md')]: {
			width: '100%'
		},
		[theme.breakpoints.down('xs')]: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-between',
			'& span': {
				margin: '.5rem',
				fontWeight: 'bolder'
			},
		}
	},
	header: {
		padding: '1rem 0 0 0'
	},
	head: {
		// marginTop: '2rem',
		marginLeft: '1rem'
	},
	button: {
		margin: '0 .1rem'
	},
	buttonWrapper: {
		// marginTop: '3rem',
		width: '40%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-around',
		[theme.breakpoints.down('md')]: {
			flexDirection: 'column',
			width: '0%',
			display: 'none'
		}
	},
	buttonWrapperMd: {
		display: 'none',
		flexDirection: 'column',
		[theme.breakpoints.down('md')]: {
			display: 'block'
		}
	},
	collapsList: {
		display: 'flex',
		margin: '2rem 0',
		'& p': {
			fontWeight: 'bold',
			margin: '0 .5rem',
			color: 'rgba(0, 0, 0, 0.6)'
		}
	},
	span: {
		fontWeight: 'bolder'
	},
	container: {
		width: '100%',
		margin: '1rem auto',
		[theme.breakpoints.down('xs')]: {
			margin: '1rem auto',
		}

	},
	filter: {
		margin: '0 1.2%',
		padding: '1rem 0',

	},
	heading: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	noJob: {
		marginLeft: '1.4%',
	},
	img: {
		minHeight: '50%',
		width: '70%',
		margin: '0 0 0 9%',
		[theme.breakpoints.down('md')]: {
			minHeight: '100%',
			width: '100%',
			margin: '13vh 0 0 0',
		}
	},
	loader: {
		width: '100%',
		height: '80vh',
		position: 'relative'
	}
}));

const Applicants = (props) => {
	const classes = useStyles();
	const { id } = useParams();

	const { users } = props;

	const [applicants, setApplicants] = useState([]);

	useEffect(() => setApplicants(users), [users]);
	console.log(props.users);



	return (
		// <AdminNavBar>
			<div className={classes.container}>
				<List className={classes.wrapper}>
					{props.users.length === 0 ? <img src={noApplicant} alt="no-data" className={classes.img} />:
					 props.users.map(applicant => (
						<ApplicantCard applicant={applicant} key={applicant.id} jobId={id} />
					 ))
					}
				</List>
			</div>
		// </AdminNavBar>
	);
};

export default Applicants;
