import makeStyles from "@material-ui/core/styles/makeStyles";

export const heading = makeStyles(theme => ({
  heading_1: {
    fontSize: '3rem',
    color: '#2d3136',
    margin: '1rem 0',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.5rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '2.5rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '2.2rem',
    }
  },
  heading_2: {
    fontSize: '1.5rem',
    color: '#2d3136',
    margin: ' 0 0 1rem 0',
    fontWeight: 'bold'
  },
  heading_3: {
    fontSize: '1.25rem',
    color: '#2d3136',
    margin: '1rem 0',
    fontWeight: 400
  },
  paragraphy_1: {
    fontSize: '1.0825rem',
    color: '#818992',
    lineHeight: 1.7,
    margin: '1rem 0',
    fontWeight: 420
  }
}))