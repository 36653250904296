/** @format */

import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Provider } from 'react-redux';
import { store } from './stores/store';
import { MuiThemeProvider, createMuiTheme}  from '@material-ui/core/styles';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-172685020-2')

const THEME = createMuiTheme({
  palette: {
    primary: {
      main: '#0E6BA6'
    }
  }
});

ReactDOM.render(
  <MuiThemeProvider theme={THEME}>
    <Provider store={store}>
      <App />
    </Provider>
  </MuiThemeProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
