export const useName = '^[A-Za-z]{3,16}$';

export const useEmail = '[a-zA-Z0-9.-_]{1,}@[0-9a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}';

export const useNumber = '^[^0( )a-zA-Z!@/#\$%\^\&*\)\(+=._-][0-9]{9}$';

export const intNumber = /^\+([0-9]{3})?()(\d{10})$/gim;

export const usePassword = '^([a-zA-Z0-9]{6,})$';
export const usePassword1 = /^(?=.*[a-z])(?=.*[a-z])(?=.*\d).{5,}$/;

export const useFiles = '/^.*\.(doc|DOC|pdf|PDF|DOCX|docx)$/';

export const useWhiteSpace = '/^[^\s][a-zA-Z\s]+/g';

export const useNumberOnly = '/^[\d-]+$/igm';

export const useUrl = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i