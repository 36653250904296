import React from 'react';
import Card from '@material-ui/core/Card';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Login from './Login';
import Signup from './Signup';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import * as useAuth from '../hooks'

const useStyles = makeStyles(theme => ({
  card: {
    // borderTop: '8px solid #F1F1F1',
    // background: '#F8F8F9 !important',
    // boxShadow: 'none',
    // // minHeight: '70vh',
    // width: 400,
    // position: 'absolute',
    // top: 150,
    // right: 120,
    // padding: 32,
    // [theme.breakpoints.down('sm')]: {
    //   top: 350,
    //   left: 0,
    //   width: '100%'
    // }
  },
}))

const RegistrationCard = () => {
  const classes = useStyles();
  const {
    confirming,
    activeForm,
    toggleActiveForm
  } = useAuth.useAuthentication();
  return (
    <RenderComponent
    activeForm={activeForm}
    toggleActiveForm={toggleActiveForm}
    />
  );
};

const RenderComponent = ({ activeForm, toggleActiveForm}) => {
  switch(activeForm) {
    case 'login': 
      return <Login toggleActiveForm={toggleActiveForm}/>
    case 'signup':
      return <Signup toggleActiveForm={toggleActiveForm}/>
    case 'forgotPassword':
      return <ForgotPassword toggleActiveForm={toggleActiveForm}/>
    case 'resetPassword':
      return <ResetPassword toggleActiveForm={toggleActiveForm}/>
    default: {
      return <></>
    }
  };
}

export default RegistrationCard;