/** @format */

import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import './loader.scss'

const center = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)'
};

const Loader = () => {
	return (
		<div style={center}>
			<div className="spinner">
		<div className="spinner-item"></div>
		<div className="spinner-item"></div>
		<div className="spinner-item"></div>
		<div className="spinner-item"></div>
		<div className="spinner-item"></div>
	</div>
		</div>
	);
};

export default Loader;
