import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import errorMsg from '../../../../../utils/errorMsg';
import { baseUrl } from '../../../../../shared/baseUrl';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import toastr from '../../../../../utils/toastr.helper';

const loginSchema = yup.object().shape({
  email: yup.string(errorMsg({
    name: 'Email address',
    type: 'email'
  })).required(errorMsg({
    name: 'Email address',
    type: 'required'
  })).min(3, errorMsg({
    name: 'Email address',
    number: 3,
    type: 'min'
  })),
  password: yup.string(
    errorMsg({
      name: 'password',
      type: 'string'
    })
  ).required(
    errorMsg({
      name: 'password',
      type: 'required'
    })
  ).min(5,
    errorMsg({
      name: 'password',
      number: 5,
      type: 'min'
    })
  ),
});

const forgotPasswordSchema = yup.object().shape({
  email: yup.string(errorMsg({
    name: 'Email address',
    type: 'email'
  })).required(errorMsg({
    name: 'Email address',
    type: 'required'
  })).min(3, errorMsg({
    name: 'Email address',
    number: 3,
    type: 'min'
  })),
});

const resetPasswordSchema = yup.object().shape({
  password: yup.string(
    errorMsg({
      name: 'password',
      type: 'string'
    })
  ).required(
    errorMsg({
      name: 'password',
      type: 'required'
    })
  ).min(5,
    errorMsg({
      name: 'password',
      number: 5,
      type: 'min'
    })
  ),
  confirmPassword: yup.string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required(),
});


export const useLogin = () => {
  const {
    errors,
    register,
    handleSubmit
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(loginSchema)
  });

  const [disabled, setDisabled] = React.useState(false);

  const history = useHistory();
  const location = useLocation();


  const onSubmit = async (value) => {
    toastr.showLoading();
    try {
      const response = await fetch(`${baseUrl}/auth/admin/login`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify(value)
      });
      const { success, token, role, id, message } = await response.json();
      if (success) {
        toastr.fire('', message, 'success')
        setDisabled(false);
        const authState = {
          token,
          role: 'admin',
          id
        }
        localStorage.removeItem('auth-state');
        localStorage.setItem('auth-state', JSON.stringify(authState));
        history.push('/portal/admin/dashboard');
      } else {
        toastr.fire('', message, 'error')
        setDisabled(false);
      }

    } catch (e) {
      toastr.fire('', 'Service unavailable', 'error');
      setDisabled(false);
    }
  };

  return {
    errors,
    register,
    handleSubmit,
    onSubmit,
    disabled
  };
};

export const useForgotPassword = () => {
  const {
    errors,
    register,
    handleSubmit
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(forgotPasswordSchema)
  });

  const [disabled, setDisabled] = React.useState(false);

  const onSubmit = async (value) => {
    toastr.showLoading();
    try {
      const response = await fetch(`${baseUrl}/auth/admin/forgot-password`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify(value)
      });
      const { success, data, message } = await response.json();
      if (success) {
        toastr.fire('', message, 'success')
        setDisabled(false);
      } else {
        toastr.fire('', message, 'error')
        setDisabled(false);
      }
    } catch (e) {
      toastr.fire('', 'Service unavailable', 'error');
      setDisabled(false);
    }
  };

  return {
    errors,
    register,
    handleSubmit,
    onSubmit,
    disabled
  };
};

export const useResetPassword = (toggleActiveForm) => {
  const {
    errors,
    register,
    handleSubmit
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(resetPasswordSchema)
  });

  const { id } = useParams();
  const history = useHistory();

  const [disabled, setDisabled] = React.useState(false);

  const onSubmit = async (value) => {
    toastr.showLoading();
    try {
      const response = await fetch(`${baseUrl}/auth/admin/reset-password/${id}`, {
        method: 'PATCH',
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify(value)
      });
      const { success, data, message } = await response.json();
      if (success) {
        toastr.fire('', message, 'success')
        setDisabled(false);
        toggleActiveForm();
        history.push('/portal/admin/login')
      } else {
        toastr.fire('', message, 'error')
        setDisabled(false);
      }

    } catch (e) {
      toastr.fire('', 'Service unavailable', 'error');
      setDisabled(false);
    }
  };

  return {
    errors,
    register,
    handleSubmit,
    onSubmit
  };
};

export const useAuthentication = () => {
  const [activeForm, setActiveForm] = React.useState('login');
  const { id } = useParams();

  React.useEffect(() => {
    if (id) {
      setActiveForm('resetPassword')
    }
  }, []);

  const toggleActiveForm = (form) => () => {
    setActiveForm(form)
  };

  return {
    activeForm,
    toggleActiveForm
  }

}