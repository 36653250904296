import React from 'react';
import Routes from './utils/routes';
import './App.scss';
import AuthProvider from './utils/authContext';

function App() {
  return (
    <AuthProvider>
      <div style={{ background: 'linear-gradient(0deg, #F6F8FD, #F6F8FD), #F6F8FD' }}>
        <Routes />
      </div>
    </AuthProvider>
  );
}

export default App;
