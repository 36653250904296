import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import EmptyComponent from './emptyComponent';
import makeStyles from '@material-ui/core/styles/makeStyles';
import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
  svg: {
    margin : '.5rem auto',
    textAlign: 'center',
    '& p': {
      fontWeight: 'bold',
    }
  },
  avater: {
    background: '#0076c0'
  },
  container: {
    // margin: '1rem 0',
  },
  root: {
    '& div': {
      // overflowY: 'unset !important'
      '& .MuiDialog-paperWidthSm': {
        width: '600px !important'
      }
    },
    
  }
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SharedDialog({setOpen, open, name, data}) {
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        className={classes.root}
      >
        <DialogTitle id="alert-dialog-slide-title">{`${name} list`}</DialogTitle>
        <DialogContent className={classes.content}>
          <div className={classes.svg}>
            {data.length === 0 ? <><EmptyComponent />
            <p>{`No ${name}`}</p></> :
            <div className={classes.container}>
              {data.map((item, index) => (
                <DocumentList index={index + 1} name={name === 'Other certificate' ? item.certName : name} link={name === 'Other certificate' ? item.certUrl : item} key={item}/>
              ))}
            </div>}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const DocumentList = ({index, link, name}) => {
  const classes = useStyles();
  return (
    <div>
      <Grid container spacing={8} alignItems='center'>
        <Grid item lg={6} md={6} sm={6} xs={6}>
          <Grid container spacing={2} alignItems='center'>
            <Grid item>
              <Avatar className={classes.avater} lg={4}>
                {index}
              </Avatar>
            </Grid>
            <Grid item >
              <p>{name}</p>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6} sm={6} xs={6}lg={6}>
          <a href={link} target="_blank" rel="noopener noreferrer" style={{float: 'right'}} >
            <Avatar className={classes.avater}>
              <VisibilityRoundedIcon />
            </Avatar>
          </a>
        </Grid>
      </Grid>
    </div>
  );
};
