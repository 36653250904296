import React from 'react'
import '../styles/quote1.scss';
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';

function Quote(props) {
  return (
    <>
    <div className="box" style={{ height: props.height}}>
      <div style={{
        borderBottom: `20px solid ${props.borderColor}`,
        borderRight: `20px solid ${props.borderColor}`,
      }} />
      <FormatQuoteIcon className="fas fa-quote-left fa2" />
        <div className="text">
          <FormatQuoteIcon className="fas fa-quote-left fa1" />
          <div>
            <h3>{ props.title }</h3>
            <p>{ props.quote }</p>
            <p>{ props.quote2 }</p>
            <p>{ props.quote3 }</p>
          </div>
        </div>
    </div>
    </>
  )
}

export default Quote
