import Button from '@material-ui/core/Button';
import React from 'react';
import TextFieldComponent from '../../../../../shared/inputs/TextFieldComponent';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import * as useAuth from '../hooks';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';

const useStyles = makeStyles(theme => ({
  link: {
    margin: '1rem 0',
    '& span': {
      marginLeft: '.5rem',
      color: theme.palette.primary.main,
      cursor: 'pointer'
    }
  },
  btn: {
    margin: '1rem 0'
  }
}))

const Signup = ({toggleActiveForm}) => {
  const classes = useStyles();
  const {
    errors,
    register,
    handleSubmit,
    onSubmit,
    disabled
  } = useAuth.useSignup();
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h6" color="initial">Sign up now</Typography>
      <TextFieldComponent
        name='firstName'
        label='First name'
        type='text'
        inputRef={register}
        error={errors.firstName}
        helperText={errors.firstName?.message}
      />
      <TextFieldComponent
        name='lastName'
        label='Last name'
        type='text'
        inputRef={register}
        error={errors.lastName}
        helperText={errors.lastName?.message}
      />
      <TextFieldComponent
        name='email'
        label='Email address'
        type='email'
        inputRef={register}
        error={errors.email}
        helperText={errors.email?.message}
      />
      <PhoneInput
      country={'ng'}
      specialLabel={'Contact number'}
      onChange={e => {
        register({
          name: 'phoneNumber',
          value: e
        })
      }}
      containerClass='w-full'
      inputClass='w-full'
      inputStyle={{ width: '100%', margin: '1rem 0', padding: '1.6rem 3.5rem', background: 'none' }}
      enableAreaCodes
      enableSearch
      inputProps={{
        required: true,
      }}
      defaultErrorMessage='Phone number'
      isValid={(value, country) => {
        if ((value.length < 14 || value.length > 14) && value.length !== 3) {
          return 'Invalid Phone number: ' + value + ', ' + country.name
        } else {
          return true;
        }
      }}
    />
      <TextFieldComponent
        name='password'
        label='Password'
        type='password'
        inputRef={register}
        error={errors.password}
        helperText={errors.password?.message}
      />
      <TextFieldComponent
        name='confirmPassword'
        label='Confirm password'
        type='password'
        inputRef={register}
        error={errors.confirmPassword}
        helperText={errors.confirmPassword?.message}
      />
      <Button type='submit' color='primary' disabled={disabled} fullWidth variant='contained' className={classes.btn}>Signup</Button>
      <Typography variant="body1" color="initial" className={classes.link}>Have an account?<span onClick={toggleActiveForm('login')}>Log In</span></Typography>
    </form>
  )
}
export default Signup;