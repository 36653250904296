/** @format */

import ResetPassword from "../components/portal/applicant/resetPassword";
import { baseUrl } from "../shared/baseUrl";


// contains all endpoints for user authentication;
export const useAuthenticate = async (type, req) => {
	switch (type) {
		// user sign up
		case 'user-signup':
			try {
				const user = await fetch('https://fivec.herokuapp.com/api/v1/auth/user/signup', {
					method: 'POST',
					headers: {
						// authorization: `JWT ${req.auth}`,
						origin: req.origin,
						'Content-type': 'application/json'
					},
					body: JSON.stringify(req.body)
				});
				const data = await user.json();
				return data;
			} catch (e) {
				console.error(e);
				break;
			}
		//user login
		case 'user-signin':
			try {
				const user = await fetch('https://fivec.herokuapp.com/api/v1/auth/user/signin', {
					method: 'POST',
					headers: {
						'Content-type': 'application/json'
					},
					body: JSON.stringify(req.body)
				});
				const data = await user.json();
				return data;
			} catch (e) {
				console.error(e);
				break;
			}

		// user forgot password
		case 'user-forgot-password':
			try {
				const user = await fetch('https://fivec.herokuapp.com/api/v1/auth/user/forgot-password', {
					method: 'POST',
					headers: {
						'Content-type': 'application/json'
					},
					body: JSON.stringify(req.body)
				});
				const data = await user.json();
				return data;
			} catch (e) {
				console.error(e);
				break;
			}

			// user reset password
			case 'user-reset-password':
			try {
				const user = await fetch(`https://fivec.herokuapp.com/api/v1/auth/user/reset-password/${req.token}`, {
					method: 'PATCH',
					headers: {
						'Content-type': 'application/json'
					},
					body: JSON.stringify(req.body)
				});
				const data = await user.json();
				return data;
			} catch (e) {
				console.error(e);
				break;
			}

		// user confirmation
		case 'confirm-applicant':
			try {
				const user = await fetch(`https://fivec.herokuapp.com/api/v1/auth/user/confirmation/${req.token}`);
				const data = await user.json();
				return data;
			} catch (e) {
				console.error(e);
				break;
			}

		// admin sign up
		case 'admin-signup':
			try {
				const user = await fetch('https://fivec.herokuapp.com/api/v1/auth/admin/signup', {
					method: 'POST',
					headers: {
						Authorization: `JWT ${req.auth}`,
						origin: req.origin,
						'Content-type': 'application/json'
					},
					body: JSON.stringify(req.body)
				});
				const data = await user.json();
				return data;
			} catch (e) {
				console.error(e);
				break;
			}

		// admin email confirmation
		case 'confirm-admin':
			try {
				const user = await fetch(`https://fivec.herokuapp.com/api/v1/auth/admin/confirm/${req.token}`);
				const data = await user.json();
				return data;
			} catch (e) {
				console.error(e);
				break;
			}

		// admin login
		case 'admin-signin':
			try {
				const user = await fetch('https://fivec.herokuapp.com/api/v1/auth/admin/login', {
					method: 'POST',
					headers: {
						'Content-type': 'application/json'
					},
					body: JSON.stringify(req.body)
				});
				const data = await user.json();
				return data;
			} catch (e) {
				console.error(e);
				break;
			}

		// admin forgot password
		case 'admin-forgot-password':
			try {
				const user = await fetch('https://fivec.herokuapp.com/api/v1/auth/admin/forgot-password', {
					method: 'POST',
					headers: {
						'Content-type': 'application/json'
					},
					body: JSON.stringify(req.body)
				});
				const data = await user.json();
				return data;
			} catch (e) {
				console.error(e);
				break;
			}

			// admin reset password
			case 'admin-rest-password':
			try {
				const resetPasswordResponse = await fetch(` https://fivec.herokuapp.com/api/v1/auth/admin/reset-password/${req.token}`, {
					method: 'PATCH',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(req.body)
				});
				const resetPassword = await resetPasswordResponse.json();
				return resetPassword;
			} catch (e) {
				console.error(e);
				break;
			}
		default:
			return;
	}
};

export const useProfile = async (type, req) => {
	switch (type) {
		// educational info
		case 'educational-info':
			const education = await fetch('https://fivec.herokuapp.com/api/v1/education', {
				method: 'POST',
				headers: {
					Authorization: `JWT ${req.auth}`,
					'Content-type': 'application/json'
				},
				body: JSON.stringify(req.body)
			});
			const result = await education.json();
			return result;

		case 'update-educational-info':
			const updateEducation = await fetch(`https://fivec.herokuapp.com/api/v1/education/${req.id}`, {
				method: 'PUT',
				headers: {
					Authorization: `JWT ${req.auth}`,
					'Content-type': 'application/json'
				},
				body: JSON.stringify(req.body)
			});
			const update = await updateEducation.json();
			return update;

		// get all educational info
		case 'all-educational-info':
			const educational = await fetch('https://fivec.herokuapp.com/api/v1/education/info/all', {
				// method: 'POST',
				headers: {
					Authorization: `JWT ${req.auth}`,
				},
				// body: JSON.stringify(req.body)
			});
			const data = await educational.json();
			return data;

		// delete educational info
		case 'delete-educational-info':
			const deleteEducational = await fetch(`https://fivec.herokuapp.com/api/v1/education/info/${req.id}`, {
				method: 'DELETE',
				headers: {
					Authorization: `JWT ${req.auth}`,
				},
				// body: JSON.stringify(req.body)
			});
			const deleteEdu = await deleteEducational.json();
			return deleteEdu;

		// work experience
		case 'work-experience':
			const workExp = await fetch('https://fivec.herokuapp.com/api/v1/work', {
				method: 'POST',
				headers: {
					Authorization: `JWT ${req.auth}`,
					'Content-type': 'application/json'
				},
				body: JSON.stringify(req.body)
			});
			const work = await workExp.json();
			return work;

		//get all work experience
		case 'all-work-experience':
			const allWorkExp = await fetch('https://fivec.herokuapp.com/api/v1/work/info/all', {
				headers: {
					Authorization: `JWT ${req.auth}`,
				},
			});
			const workInfoData = await allWorkExp.json();
			return workInfoData;

		// work experience
		case 'update-work-experience':
			const UpdateworkExp = await fetch(`https://fivec.herokuapp.com/api/v1/work/${req.id}`, {
				method: 'PUT',
				headers: {
					Authorization: `JWT ${req.auth}`,
					'Content-type': 'application/json'
				},
				body: JSON.stringify(req.body)
			});
			const updateWork = await UpdateworkExp.json();
			return updateWork;

		//get all work experience
		case 'delete-work-experience':
			const deleteWorkExp = await fetch(`https://fivec.herokuapp.com/api/v1/work/info/${req.id}`, {
				method: 'DELETE',
				headers: {
					Authorization: `JWT ${req.auth}`,
				},
			});
			const deleteWork = await deleteWorkExp.json();
			return deleteWork;
		// get user details
		case 'user-profile': 
			try {
				const user = await fetch(`https://fivec.herokuapp.com/api/v1/auth/user/${req.id}`, {
					headers: {
						Authorization: `JWT ${req.auth}`
					}
				});
				const userData = await user.json();
				return userData;
			} catch (e) {
				console.error(e);
				break;
			}

		// update user profile
		case 'update-user':
			try {
				const updateUser = await fetch('https://fivec.herokuapp.com/api/v1/auth/user', {
					method: 'PATCH',
					headers: {
						Authorization: `JWT ${req.auth}`,
						'Content-type': 'application/json'
					},
					body: JSON.stringify(req.body)
				});
				const userRes = await updateUser.json();
				return userRes;
			} catch (e) {
				console.error(e);
				break;
			}
		// add skill
		case 'add-skill':
			try {
				const skillRes = await fetch('https://fivec.herokuapp.com/api/v1/skills', {
					method: 'POST',
					headers: {
						Authorization: `JWT ${req.auth}`,
						'Content-type': 'application/json'
					},
					body: JSON.stringify(req.body)
				});
				const skill = await skillRes.json();
				return skill;
			} catch (e) {
				console.error(e);
				break;
			}

		// add skill
		case 'update-skill':
			try {
				const skillRes = await fetch(`https://fivec.herokuapp.com/api/v1/skills/${req.id}`, {
					method: 'PUT',
					headers: {
						Authorization: `JWT ${req.auth}`,
						'Content-type': 'application/json'
					},
					body: JSON.stringify(req.body)
				});
				const skill = await skillRes.json();
				return skill;
			} catch (e) {
				console.error(e);
				break;
			}

		case 'delete-skill':
			try {
				const skillRes = await fetch(`https://fivec.herokuapp.com/api/v1/skills/info/${req.id}`, {
					method: 'DELETE',
					headers: {
						Authorization: `JWT ${req.auth}`,
						// 'Content-type': 'application/json'
					},
					// body: JSON.stringify(req.body)
				});
				const skill = await skillRes.json();
				return skill;
			} catch (e) {
				console.error(e);
				break;
			}
		// get user skills
		case 'get-skills':
			try {
				const skillsRes = await fetch('https://fivec.herokuapp.com/api/v1/skills/info/all', {
					headers: {
						Authorization: `JWT ${req.auth}`,
					}
				})
				const skillData = await skillsRes.json();
				return skillData;
			} catch (e) {
				console.error(e);
				break;
			}
		// }

		case 'upload-resume':
			try {
				const resumeRes = await fetch(`https://fivec.herokuapp.com/api/v1/auth/user/resume/upload/`, {
					method: 'PATCH',
					headers: {
						Authorization: `JWT ${req.auth}`,
					},
					body: req.body
				});
				const resume = await resumeRes.json();
				return resume;
			} catch (e) {
				console.error(e);
				break;
			}

			case 'upload-professionalCert':
			try {
				const resumeRes = await fetch(` https://fivec.herokuapp.com/api/v1/auth/user/certificate/upload/`, {
					method: 'PATCH',
					headers: {
						Authorization: `JWT ${req.auth}`,
					},
					body: req.body
				});
				const resume = await resumeRes.json();
				return resume;
			} catch (e) {
				console.error(e);
				break;
			}

			case 'delete-resume':
			try {
				const resumeRes = await fetch(`https://fivec.herokuapp.com/api/v1/auth/user/delete/resume`, {
					method: 'DELETE',
					headers: {
						Authorization: `JWT ${req.auth}`,
					},
				});
				const resume = await resumeRes.json();
				return resume;
			} catch (e) {
				console.error(e);
				break;
			}

			case 'delete-certificate':
			try {
				const certificateRes = await fetch(` https://fivec.herokuapp.com/api/v1/auth/user/delete/certificate`, {
					method: 'DELETE',
					headers: {
						Authorization: `JWT ${req.auth}`,
					},
				});
				const certificate = await certificateRes.json();
				return certificate;
			} catch (e) {
				console.error(e);
				break;
			}
		default:
			return;
	}
}

export const useNigStates = async (type, state) => {
	switch (type) {
		case 'state':
			try {
				const state = await fetch('http://locationsng-api.herokuapp.com/api/v1/states');
				const stateRes = await state.json();
				return stateRes;
			} catch (e) {
				console.error(e);
				break;
			}
		case 'lga':
			try {
				const lga = await fetch(`http://locationsng-api.herokuapp.com/api/v1/states/${state}/lgas`);
				const lgaRes = await lga.json();
				return lgaRes;
			} catch (e) {
				console.error(e);
				break;
			}
		default:
			return;
	}
}

export const useUniversal = async (type, req) => {
	switch (type) {
		case 'token':
			try {
				const state = await fetch("https://www.universal-tutorial.com/api/getaccesstoken", {
					headers: {
						"Accept": "application/json",
						"api-token": "lo2LK-_VEMtCokzaWOq1p-HD0w1t5xbAISccGip9-80R5bcLasU6fKjC_QXOxmXS93o",
						"user-email": "chinweike89@gmail.com"
					}
				});
				const stateData = state.json();
				return stateData;
			} catch (e) {
				console.error(e);
				break;
			}

		case 'country':
			try {
				const state = await fetch("https://www.universal-tutorial.com/api/countries/", {
					headers: {
						"Accept": "application/json",
						"Authorization": `Bearer ${req.auth}`,
					}
				});
				const stateData = state.json();
				return stateData;
			} catch (e) {
				console.error(e);
				break;
			}

		case 'state':
			try {
				const state = await fetch(`https://www.universal-tutorial.com/api/states/${req.state}`, {
					headers: {
						"Accept": "application/json",
						"Authorization": `Bearer ${req.auth}`,
					}
				});
				const stateData = state.json();
				return stateData;
			} catch (e) {
				console.error(e);
				break;
			}
		case 'cities':
			try {
				const state = await fetch(`https://www.universal-tutorial.com/api/cities/${req.cities}`, {
					headers: {
						"Accept": "application/json",
						"Authorization": `Bearer ${req.auth}`,
					}
				});
				const stateData = state.json();
				return stateData;
			} catch (e) {
				console.error(e);
				break;
			}
		default:
			return;
	}
}

export const useAdminFunctions = async (type, req) => {
	switch (type) {
		case 'get-all-admin':
			try {
				const admin = await fetch('https://fivec.herokuapp.com/api/v1/auth/admin/', {
					headers: {
						Authorization: `JWT ${req.auth}`,
						'Content-Type': 'application/json'
					},
				});
				const result = await admin.json();
				return result;
			} catch(e) {
				console.error(e);
				break;
			}
		case 'delete-admin':
			try {
				const admin = await fetch(`https://fivec.herokuapp.com/api/v1/auth/admin/${req.id}`, {
					method: 'DELETE',
					headers: {
						Authorization: `JWT ${req.auth}`,
						'Content-Type': 'application/json'
					},
				});
				const result = await admin.json();
				return result;
			} catch(e) {
				console.error(e);
				break;
			}
		case 'post-a-job':
			try {
				const postJobResponse = await fetch('https://fivec.herokuapp.com/api/v1/jobs', {
					method: 'POST',
					headers: {
						Authorization: `JWT ${req.auth}`,
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(req.body)
				});
				const postJob = await postJobResponse.json();
				return postJob;
			} catch (e) {
				console.error(e);
				break;
			}
		case 'job-listing':
			try {
				const jobListingResponse = await fetch('https://fivec.herokuapp.com/api/v1/jobs/posts/all', {
					method: 'GET',
					headers: {
						Authorization: `JWT ${req.auth}`,
						'Content-Type': 'application/json'
					}
				});
				const jobList = await jobListingResponse.json();
				return jobList;
			} catch (e) {
				console.error(e);
				break;
			}

		case 'cities':
			try {
				const state = await fetch(`https://www.universal-tutorial.com/api/cities/${req.cities}`, {
					headers: {
						"Accept": "application/json",
						"Authorization": `Bearer ${req.auth}`,
					}
				});
				const stateData = state.json();
				return stateData;
			} catch (e) {
				console.error(e);
				break;
			}


		case 'job-details':
			try {
				const jobDetailsResponse = await fetch(`https://fivec.herokuapp.com/api/v1/jobs/post/${req.id}`, {
					method: 'GET',
					headers: {
						Authorization: `JWT ${req.auth}`,
						'Content-Type': 'application/json'
					}
				});
				const jobDetails = jobDetailsResponse.json();
				return jobDetails;
			} catch (e) {
				console.error(e);
				break;
			}

		// short list appllicant
		case 'short-list':
			try {
				const shortListRes = await fetch(`https://fivec.herokuapp.com/api/v1/jobs/short-list/${req.id}`, {
					method: 'POST',
					headers: {
						Authorization: `JWT ${req.auth}`,
						'Content-Type': 'application/json',
						origin: req.origin
					},
					body: JSON.stringify(req.body)
				})
				const shortList = await shortListRes.json();
				return shortList;
			} catch (e) {
				console.error(e);
				break;
			}

		case 'delete-applicant':
			try {
				const deleteApplicantResponse = await fetch(`https://fivec.herokuapp.com/api/v1/jobs/delete/${req.id}`, {
					method: 'DELETE',
					headers: {
						Authorization: `JWT ${req.auth}`,
						'Content-Type': 'application/json',
						origin: req.origin
					},
					body: JSON.stringify(req.body)
				});
				const deleteApplicant = await deleteApplicantResponse.json();
				return deleteApplicant;
			} catch (e) {
				console.error(e);
				break;
			}

		// close job
		case 'close-job':
			try {
				const closeJobRes = await fetch(`https://fivec.herokuapp.com/api/v1/jobs/close-job/${req.id}`, {
					method: 'POST',
					headers: {
						Authorization: `JWT ${req.auth}`,
						'Content-Type': 'application/json'
					}
				})
				const closeJob = await closeJobRes.json();
				return closeJob;
			} catch (e) {
				console.error(e);
				break;
			}
		case 'get-all-users':
			try {
				const allUsersResponse = await fetch('https://fivec.herokuapp.com/api/v1/auth/user', {
					method: 'GET',
					headers: {
						Authorization: `JWT ${req.auth}`,
						'Content-Type': 'application/json'
					}
				});
				const allUsers = await allUsersResponse.json();
				return allUsers;
			} catch (e) {
				console.error(e);
				break;
			}
		case 'admin-details':
			try {
				const adminDetailResponse = await fetch(`https://fivec.herokuapp.com/api/v1/auth/admin/${req.id}`, {
					method: 'GET',
					headers: {
						Authorization: `JWT ${req.auth}`,
						'Content-Type': 'application/json'
					}
				});
				const adminDetails = await adminDetailResponse.json();
				return adminDetails;
			} catch (e) {
				console.error(e);
				break;
			}

		case 'sort-applicants':
			try {
				const sortApplicantResponse = await fetch(`https://fivec.herokuapp.com/api/v1/jobs/qualified-aplicants/${req.id}?term=${req.term}`, {
					method: 'GET',
					headers: {
						Authorization: `JWT ${req.auth}`,
						'Content-Type': 'application/json'
					}
				});
				const sortApplicant = await sortApplicantResponse.json();
				return sortApplicant;
			} catch (e) {
				console.error(e);
				break;
			}

		case 'get-all-admins':
			try {
				const getAdminsResponse = await fetch('https://fivec.herokuapp.com/api/v1/auth/admin/', {
					method: 'GET',
					headers: {
						Authorization: `JWT ${req.auth}`,
						'Content-Type': 'application/json'
					}
				});
				const getAdmins = getAdminsResponse.json();
				return getAdmins;
			} catch (e) {
				console.error(e);
				break;
			}

		case 'get-single-admin':
			try {
				const getAdminResponse = await fetch(`https://fivec.herokuapp.com/api/v1/auth/admin/${req.id}`, {
					method: 'GET',
					headers: {
						Authorization: `JWT ${req.auth}`,
						'Content-Type': 'application/json'
					}
				});
				const getAdmin = await getAdminResponse.json();
				return getAdmin;
			} catch (e) {
				console.error(e);
				break;
			}

		case 'update-profile':
			try {
				const updateProfileResponse = await fetch('https://fivec.herokuapp.com/api/v1/auth/admin', {
					method: 'PATCH',
					headers: {
						Authorization: `JWT ${req.auth}`,
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(req.body)
				});
				const updateProfile = await updateProfileResponse.json();
				return updateProfile;
			} catch (e) {
				console.error(e);
				break;
			}

			case 'edit-job':
				try {
					const editJobResponse = await fetch(`https://fivec.herokuapp.com/api/v1/jobs/${req.id}`, {
						method: 'PUT',
						headers: {
							Authorization: `JWT ${req.auth}`,
							'Content-Type': 'application/json'
						},
						body: JSON.stringify(req.body)
					});
					const editJob = await editJobResponse.json();
					return editJob;
				} catch (e) {
					console.error(e);
					break;
				}

		/* applicant bookamark APIs */
		case 'create-applicant-pool':
			try {
				const createPoolResponse = await fetch('https://fivec.herokuapp.com/api/v1/pools/create', {
					method: 'POST',
					headers: {
						Authorization: `JWT ${req.auth}`,
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(req.body)
				});
				const createPool = await createPoolResponse.json();
				return createPool;
			} catch (e) {
				console.error(e);
				break;
			}

		case 'get-all-pools':
			try {
				const allPoolsResponse = await fetch('https://fivec.herokuapp.com/api/v1/pools/all', {
					method: 'GET',
					headers: {
						Authorization: `JWT ${req.auth}`,
						'Content-Type': 'application/json'
					}
				});
				const allPools = await allPoolsResponse.json();
				return allPools;
			} catch (e) {
				console.error(e);
				break;
			}

		case 'add-applicant-to-pool':
			try {
				const addApplicantResponse = await fetch('https://fivec.herokuapp.com/api/v1/pools/add', {
					method: 'POST',
					headers: {
						Authorization: `JWT ${req.auth}`,
						'Content-type': 'application/json'
					},
					body: JSON.stringify(req.body)
				});
				const addApplicant = await addApplicantResponse.json();
				return addApplicant;
			} catch (e) {
				console.error(e);
				break;
			}

		case 'delete-pool':
			try {
				const deletePoolResponse = await fetch(`https://fivec.herokuapp.com/api/v1/pools/${req.id}`, {
					method: 'DELETE',
					headers: {
						Authorization: `JWT ${req.auth}`,
						'Content-Type': 'application/json'
					}
				});
				const deletePool = await deletePoolResponse.json();
				return deletePool;
			} catch (e) {
				console.error(e);
				break;
			}

		case 'delete-bookmarked-applicant':
			try {
				const deleteApplicantResponse = await fetch(`https://fivec.herokuapp.com/api/v1/pools/remove/${req.id}`, {
					method: 'DELETE',
					headers: {
						Authorization: `JWT ${req.auth}`,
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(req.body)
				});
				const deleteApplicant = await deleteApplicantResponse.json();
				return deleteApplicant;
			} catch (e) {
				console.error(e);
				break;
			}

			case 'admin-change-password': {
			try {
				const changePasswordRes = await fetch(`https://fivec.herokuapp.com/api/v1/auth/admin/change-password`, {
					method: 'PATCH',
					headers: {
						Authorization: `JWT ${req.auth}`,
						'content-type': 'application/json',
					},
					body: JSON.stringify(req.body)
				});
				const changePassword = await changePasswordRes.json();
				return changePassword;
			}
			catch (e) {
				console.error(e);
				break;
			}
		}
			case 'bookmark-shortlist': {
			try {
				const bookmarkShortListRes = await fetch(`https://fivec.herokuapp.com/api/v1/pools/get-fit/${req.id}`, {
					method: 'GET',
					headers: {
						Authorization: `JWT ${req.auth}`,
						'content-type': 'application/json',
					},
				});
				const shortlistCandidate = await bookmarkShortListRes.json();
				return shortlistCandidate;
			}
			catch (e) {
				console.error(e);
				break;
			}
		}

		default:
			return;
	}
};

export const useJobs = async (type, req) => {
	switch (type) {
		case 'get-all-jobs':
			try {
				const jobListRes = await fetch('https://fivec.herokuapp.com/api/v1/jobs/posts/all', {
					headers: {
						Authorization: `JWT ${req.auth}`
					}
				});
				const jobList = await jobListRes.json();
				return jobList;
			} catch (e) {
				console.error(e);
				break;
			}

		case 'get-one-job':
			try {
				const jobListRes = await fetch(`https://fivec.herokuapp.com/api/v1/jobs/post/${req.id}`, {
					headers: {
						Authorization: `JWT ${req.auth}`
					}
				});
				const jobList = await jobListRes.json();
				return jobList;
			} catch (e) {
				console.error(e);
				break;
			}

		case 'search-job':
			try {
				const jobListRes = await fetch(`https://fivec.herokuapp.com/api/v1/jobs/search?term=${req.term}`, {
					headers: {
						Authorization: `JWT ${req.auth}`
					}
				});
				const jobList = await jobListRes.json();
				return jobList;
			} catch (e) {
				console.error(e);
				break;
			}

		case 'apply-for-job': {
			try {
				const applyJobRes = await fetch(`https://fivec.herokuapp.com/api/v1/jobs/apply/${req.id}`, {
					method: 'POST',
					headers: {
						Authorization: `JWT ${req.auth}`,
						origin: req.origin
					},
					body: req.body
				});
				const apply = await applyJobRes.json();
				return apply;
			}
			catch (e) {
				console.error(e);
				break;
			}
		}

		case 'favourite-job': {
			try {
				const applyJobRes = await fetch(`https://fivec.herokuapp.com/api/v1/jobs/post/favourite/${req.id}`, {
					method: 'PATCH',
					headers: {
						Authorization: `JWT ${req.auth}`,
					},
					body: req.body
				});
				const apply = await applyJobRes.json();
				return apply;
			}
			catch (e) {
				console.error(e);
				break;
			}
		}

		case 'get-favourite-job':
			try {
				const appliedJobsRes = await fetch('https://fivec.herokuapp.com/api/v1/jobs/posts/favourite/all', {
					headers: {
						Authorization: `JWT ${req.auth}`
					}
				});
				const appliedJob = await appliedJobsRes.json();
				return appliedJob;
			} catch (e) {
				console.error(e);
				break;
			}

		case 'applied-jobs':
			try {
				const appliedJobsRes = await fetch('https://fivec.herokuapp.com/api/v1/auth/user/applied', {
					headers: {
						Authorization: `JWT ${req.auth}`
					}
				});
				const appliedJob = await appliedJobsRes.json();
				return appliedJob;
			} catch (e) {
				console.error(e);
				break;
			}
		default:
			return;
	}
}

export const locationsApi = async (type, req) => {
	switch(type) {
		case 'country': {
			try {
				const request = await fetch(`${baseUrl}/location`, {
					headers: {
						Authorization: `JWT ${req.auth}`,
					},
				});
				const countries = await request.json();
				return countries
			} catch (e) {
				console.error(e);
				break;
			}
		}
		case 'states': {
			try {
				const request = await fetch(`${baseUrl}/location/regions?country=${req.country}
				`, {
					headers: {
						Authorization: `JWT ${req.auth}`,
					},
				});
				const states = await request.json();
				return states
			} catch (e) {
				console.error(e);
				break;
			}
		}
		case 'cities': {
			try {
				const request = await fetch(`${baseUrl}/location/city/${req.country}/?region=${req.region}`, {
					headers: {
						Authorization: `JWT ${req.auth}`,
					},
				});
				const cities = await request.json();
				return cities
			} catch (e) {
				console.error(e);
				break;
			}
		}
		default: {
			return type;
		}
	}
}

