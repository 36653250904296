import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: {}
}
const applicantSlice = createSlice({
  name: 'applicant',
  initialState,
  reducers: {
    getApplicant: (state, actions) => {
      state.data = actions.payload;
    }
  }
});

export const { getApplicant } = applicantSlice.actions;

export default applicantSlice.reducer;