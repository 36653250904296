/* eslint-disable react-hooks/rules-of-hooks */
/** @format */

import React from 'react';
// node moudles
import { makeStyles, Grid, Divider } from '@material-ui/core';
import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded';
import WorkRoundedIcon from '@material-ui/icons/WorkRounded';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import WorkOutlineRoundedIcon from '@material-ui/icons/WorkOutlineRounded';
import Avatar from '@material-ui/core/Avatar';
import { useSelector } from 'react-redux';
import Facebook from 'react-sharingbuttons/dist/buttons/Facebook';
import Twitter from 'react-sharingbuttons/dist/buttons/Twitter';
import Email from 'react-sharingbuttons/dist/buttons/Email';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import 'react-sharingbuttons/dist/main.css'
import { HtmlParser } from '../shared/htmlParser';
import { RippleImpactBtn } from './animatedBtn';
import { useDispatch } from 'react-redux';
import { useAdminFunctions } from '../hooks/useFetch';
import cogoToast from 'cogo-toast';
import { useHistory, useParams } from 'react-router';
import { useContext } from 'react';
import { auth } from '../utils/authContext';

const useStyles = makeStyles(theme => ({
	root: {
		background: '#fff',
		boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0.1rem 0.3rem, rgba(0, 0, 0, 0.05) 0px 0.1rem 0.2rem, rgba(0, 0, 0, 0.05) 0px 0.5rem 1.5rem',
		borderRadius: '8px',
		minHeight: '100vh',
		width: '100%',
		// margin: '1rem auto',
		boxSizing: 'border-box',
    [theme.breakpoints.down('xs')]:{
      width: '99%'
    },
    [theme.breakpoints.down('sm')]:{
      width: '99%'
    },
    [theme.breakpoints.down('md')]:{
      width: '99%'
    }
	},
	des: {
		display: 'flex',
		// justifyContent: 'space-between',
		alignItem: 'center',
		margin: '1rem 0',
		'& div': {
			marginRight: '.7rem',
			display: 'flex',
			'& p': {
				color: '#111',
				margin: '0 .5rem'
			}
		},
		[theme.breakpoints.down('xs')]: {
			flexDirection: 'column',
			'& div': {
				margin: '.5rem .7rem .5rem 0'
			}
		}
	},
	header: {
		color: '#111',
		// margin: '0 1.5rem'
  },
  content: {
    margin: '2rem 1.5rem'
	},
	loader: {
    position: 'relative',
    height: '100vh',
    width: '100%'
	},
	btn: {
		[theme.breakpoints.down('xs')]: {
			margin: '1rem'
		}
	},
	social: {
		margin: '.5rem',
		'& h4': {
			margin: '1.1rem .5rem'
		}
	}
}));

const SingleJob = ({isApplied, type}) => {
	const classes = useStyles();
	const job = useSelector(state => state.job.data);
	return (
		<div className={classes.root}>
			<div>
				<Title data={job} isApplied={isApplied} type={type}/>
			</div>
			<Divider/>
			<Body data={job}/>
			<ShareOnSocialMedia data={job}/>
		</div>
	);
};

const Title = ({ data, type, isApplied,}) => {
	const classes = useStyles();

	const history = useHistory();

	const { id } = useParams();

	const [ setAuth ] = useContext(auth);

	const closeJob = () => {
		const toast = cogoToast.loading('Closing job...')
    useAdminFunctions('close-job', {
      auth: setAuth({ type: 'get-token' }),
      id: id,
    })
      .then((data) => {
        toast.then(() => {
          if (data.success) {
            cogoToast.success(data.message)
            history.push('/portal/admin/job')
          } else {
            cogoToast.error(data.message)
          }
        })
      })
      .catch((e) => {
        console.error(e)
        cogoToast.error('Service unavailable')
      })
  }

	return (
		<Grid container spacing={2} style={{margin: '.5rem'}}>
			<Grid item lg={10}>
				<div className={classes.header}>
					<Grid container spacing={2}>
						<Grid item lg={12}>
							<h3>{data.jobTitle}</h3>
							{isApplied ? <p style={{fontWeight: 'bold'}}>(You have already applied for this Job)</p> : <></>}
						</Grid>
					</Grid>
					<div className={classes.des}>
						<div>
							<WorkRoundedIcon color='primary'/>
							<p>{data.category}</p>
						</div>
						<div>
							<LocationOnRoundedIcon color="secondary" />
							<p>{data.jobLocation}</p>
						</div>
						<div>
							<QueryBuilderIcon style={{color: '#32CD32'}} />
							<p>{data.jobType}</p>
						</div>
					</div>
				</div>
			</Grid>
			{type === 'admin' && data.status !== 'closed' ? <Grid item lg={2}>
				<RippleImpactBtn content='Close Job' type='small' onClick={closeJob}/>
			</Grid> : <></>}
		</Grid>
	);
};

const Body = ({data}) => {
	const classes = useStyles();
	return (
		<div>
			<div className={classes.content}>
				<header>
					<h5>Job Description</h5>
					
				</header>
				<div>
					<p>
						<HtmlParser data={data.jobDescription} />
					</p>
				</div>
			</div>
			<Divider/>
				<div className={classes.content}>
					<header>
						<h5>Required Skills</h5>
					</header>
					<div>
						<p>
							<HtmlParser data={data.skills} />
						</p>
					</div>
				</div>
				<Divider/>
				<div className={classes.content}>
					<header>
						<h5>Qualifications</h5>
					</header>
					<div>
						<p>{data.qualification}</p>
					</div>
				</div>
				<Divider/>
				<div className={classes.content}>
					<header>
						<h5>Years of Experience</h5>
					</header>
					<div>
						<p>{data.yearsOfExperience}</p>
					</div>
				</div>
			<Divider />
			{ data.reportingLine &&
				<div className={classes.content}>
					<header>
						<h5>Reporting Line</h5>
					</header>
					<div>
						<p>{data.reportingLine}</p>
					</div>
				</div>
			}

			{
				data.reportingLine && <Divider/>
			}
		</div>
	);
};


const ShareOnSocialMedia = ({data}) => {
	const url = window.location.href
	const shareText = `Apply for ${data.jobTitle}`;
	const classes = useStyles();
	return (
		<div className={classes.social}>
			<h4>Share Job Post</h4>
			<Facebook url={url} />
      <Twitter url={url} shareText={shareText} />
			<Email url={url} shareText={shareText} />
			<a href={`https://www.linkedin.com/sharing/share-offsite/?url=${url}"target="_blank" title="Share on LinkedIn`} class="react-sharing-button__link react-sharing-button--twitter" target='_blank' rel='onoper noreferre'>
			<LinkedInIcon />
  		<span class="react-sharing-button__text">LinkedIn</span>
			</a>
		</div>
	)
}

export default SingleJob;
