/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useContext } from 'react'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import MenuItem from '@material-ui/core/MenuItem';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import cogoToast from 'cogo-toast';
import { useAdminFunctions } from '../../../../hooks/useFetch';
import { auth } from '../../../../utils/authContext';

const useStyles = makeStyles((theme) => ({
  content: {
    width: '600px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    }
  }
}));

const CreatePool = ({ handleShow, show, setShow, applicantId }) => {
  const classes = useStyles();
  const [selectedPool, setSelectedPool] = useState('');
  const [allPools, setAllPools] = useState([]);
  const [setAuth] = useContext(auth);

  const handleClose = () => {
    setShow(!show);
  };

  const handleChange = (e) => {
    setSelectedPool(e.target.value);
  }

  /* get all available pools */
  const handleAllPools = () => {
    // display modal
    handleShow();

    const req = {
      auth: setAuth({ type: 'get-token' })
    }
    useAdminFunctions('get-all-pools', req)
    .then(data => {
      setAllPools(data.data)
    })
    .catch(e => {
      console.error(e);
    })
  }


  const handleAddApplicant = () => {
    const req = {
      auth: setAuth({ type: 'get-token' }),
      body: {
        userId: applicantId,
        candidatePoolId: selectedPool
      }
    }
    useAdminFunctions('add-applicant-to-pool', req)
    .then(data => {
      const toast = cogoToast.loading('Adding applicant to pool...');
        toast.then(() => {
          if (data.success) {
            cogoToast.success(data.message);
            setSelectedPool('');
          } else {
            cogoToast.error(data.message);
            setSelectedPool('');
          }
        });
    })
    .catch(e => {
      console.error(e);
      cogoToast.error('Service unavailable');
    })
    handleClose();
  }

  return (
    <div>
      {/* <Button variant="outlined" color="primary" onClick={handleShow}> */}
        <Tooltip title="bookmark applicant" placement="left">
        <StarRoundedIcon onClick={handleAllPools} fontSize="large" style={{ color: 'gold' }} />
        </Tooltip>
      {/* </Button> */}
      <Dialog open={show} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Please select from the pool below</DialogTitle>
        <DialogContent className={classes.content}>
          <TextField
          id="filled-select-currency"
          select
          label="Select pool name"
          value={selectedPool}
          onChange={handleChange}
          variant="outlined"
        >
          {allPools.map((pool) => (
            <MenuItem key={pool.id} value={pool.id}>
              {pool.projectName}
            </MenuItem>
          ))}
        </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddApplicant} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CreatePool;

