import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import InstagramIcon from '@material-ui/icons/Instagram';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import UpdateOutlinedIcon from '@material-ui/icons/UpdateOutlined';
import BuildOutlinedIcon from '@material-ui/icons/BuildOutlined';
import SchoolOutlinedIcon from '@material-ui/icons/SchoolOutlined';
import { Avatar } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  h4: {
    fontWeight: 'bolder',
		margin: '1rem 0',
		display: 'flex',
		alignItems: 'center',
		fontSize: '24px',
		color: '#39393a'

	},
	main: {
		marginLeft: '2%',
	},
  span: {
    fontWeight: 'bolder'
  }
}));

const styles = theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(2)
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500]
	}
});

const DialogTitle = withStyles(styles)(props => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const DialogContent = withStyles(theme => ({
	root: {
    padding: theme.spacing(2),
    width: theme.spacing(70),
    [theme.breakpoints.down('xs')]: {
      width: theme.spacing(38),
    }
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(1)
	}
}))(MuiDialogActions);

const CustomizedDialogs = ({ applicant, setOpen, open, setShowResume }) => {
	const classes = useStyles();

	const handleClose = () => {
		setOpen(false);
	};
	return (
		<div>
				<Dialog aria-labelledby="customized-dialog-title" open={open} >
				<DialogTitle id="customized-dialog-title" onClose={handleClose}>
					Applicant Details.
				</DialogTitle>
				<DialogContent dividers>
					{/* <Typography gutterBottom> */}
					<div className={classes.h4}>
						<Avatar style={{background: 'black'}}><AccountCircleOutlinedIcon  fontSize="large"/></Avatar> <span className={classes.main}>Bio data</span>
					</div>
					{/* </Typography> */}
					<Typography gutterBottom><span className={classes.span}>Full Name: </span>{`${applicant.firstName ? `${applicant.firstName}` : 'Not specified'} ${applicant.lastName ? `${applicant.lastName}` : 'Not specified'}`}</Typography>
					<Typography gutterBottom><span className={classes.span}>Gender: </span>{`${applicant.gender}`}</Typography>
					<Typography gutterBottom><span className={classes.span}>Date of birth: </span>{`${applicant.dateOfBirth}`}</Typography>
					<Typography gutterBottom><span className={classes.span}>Nationality: </span>{`${applicant.nationality}`}</Typography>
					<Typography gutterBottom><span className={classes.span}>Email address: </span>{`${applicant.email}`}</Typography>
					<Typography gutterBottom><span className={classes.span}>Alternative email address: </span>{`${applicant.alternativeEmail}`}</Typography>
					<Typography gutterBottom><span className={classes.span}>Marital status: </span>{`${applicant.maritalStatus}`}</Typography>
					<Typography gutterBottom><span className={classes.span}>Phone number: </span>{`${applicant.mobile}`}</Typography>
					<Typography gutterBottom><span className={classes.span}>Telephone number: </span>{`${applicant.telephone}`}</Typography>
					<Typography gutterBottom><span className={classes.span}>Address: </span>{`${applicant.address}`}</Typography>
					<Typography gutterBottom><span className={classes.span}>Language: </span>
						{`${applicant.languages ? `(${applicant.languages})` : 'Not specified'}`}</Typography>
					{/* <Typography gutterBottom> */}
					<div className={classes.h4}>
						<Avatar style={{background: 'black'}}><InstagramIcon fontSize="large" /></Avatar> <span className={classes.main}>Social Network</span>
					</div>
						{/* </Typography> */}
					<Typography gutterBottom><span className={classes.span}>Facebook: </span>{`${applicant.faceBook}`}</Typography>
					<Typography gutterBottom><span className={classes.span}>LinkedIn: </span>{`${applicant.linkedIn}`}</Typography>
					<Typography gutterBottom><span className={classes.span}>Skype: </span>{`${applicant.skypeId}`}</Typography>
					{/* <Typography gutterBottom dividers> */}
					<div className={classes.h4}>
						<Avatar style={{background: 'black'}}><InsertDriveFileOutlinedIcon fontSize="large"/></Avatar> <span className={classes.main}>Document</span>
					</div>
					{/* </Typography> */}
					<Typography gutterBottom><span className={classes.span}>Degree certificate one: </span>Bachelor of Engineering</Typography>
					<Typography gutterBottom><span className={classes.span}>Degree certificate two: </span>Not applicable</Typography>
					<Typography gutterBottom><span className={classes.span}>Degree certificate three: </span>Not applicable</Typography>
					<Typography gutterBottom><span className={classes.span}>Professional certificate: </span> ICAN</Typography>
					<Typography gutterBottom><span className={classes.span}>Professional certificate two: </span> COREN </Typography>
					{/* <Typography gutterBottom dividers> */}
					<div className={classes.h4}>
						<Avatar style={{background: 'black'}}><SchoolOutlinedIcon fontSize="large" /></Avatar> <span className={classes.main}>Education</span>
					</div>
					{/* </Typography> */}
					<Typography gutterBottom><span className={classes.span}>School: </span> University of British Columbia</Typography>
					<Typography gutterBottom><span className={classes.span}>Qualification: </span> Bachelor of Engineering</Typography>
					<Typography gutterBottom><span className={classes.span}>Country: </span> Canada</Typography>
					<Typography gutterBottom><span className={classes.span}>Start date: </span> 12/12/2012</Typography>
					<Typography gutterBottom><span className={classes.span}>End date: </span> 14/12/2014</Typography>
					<Typography gutterBottom dividers>
						<div className={classes.h4}>
							<Avatar style={{background: 'black'}}><BuildOutlinedIcon fontSize="large" /></Avatar> <span className={classes.main}>Skill</span>
						</div>
					</Typography>
					<Typography gutterBottom><span className={classes.span}>Specialization: </span>{` ${applicant.areaOfExpertise}`}</Typography>
					<Typography gutterBottom><span className={classes.span}>Proficiency: </span> 7</Typography>
					<Typography gutterBottom dividers></Typography>
					<Typography gutterBottom dividers>
						<div className={classes.h4}>
							<Avatar style={{background: 'black'}}><UpdateOutlinedIcon fontSize="large" /></Avatar> <span className={classes.main}>Work Experience</span>
					</div>
					</Typography>
					<Typography gutterBottom><span className={classes.span}>Job title: </span> Software Engineer</Typography>
					<Typography gutterBottom><span className={classes.span}>Company: </span>{` ${applicant.currentWorkPlace}`}</Typography>
					<Typography gutterBottom><span className={classes.span}>Job description: </span> Software developer</Typography>
					<Typography gutterBottom><span className={classes.span}>Years of experience: </span>{` ${applicant.totalYearsOfExperience}`} </Typography>
					<Typography gutterBottom><span className={classes.span}>Area of expertise: </span>{` ${applicant.areaOfExpertise}`}</Typography>
					<Typography gutterBottom><span className={classes.span}>Desired job industries: </span>{`${applicant.desiredJobIndustry}`}</Typography>
					<Typography gutterBottom><span className={classes.span}>Desired work location: </span>{`${applicant.desiredWorkLocation}`}</Typography>
					<Typography gutterBottom><span className={classes.span}>Current salary: </span>{`${applicant.currentSalary}`}</Typography>
					<Typography gutterBottom><span className={classes.span}>Expected salary: </span>{`${applicant.expectedSalary}`}</Typography>
					<Typography gutterBottom><span className={classes.span}>Availability period: </span>{`${applicant.availability}`}</Typography>
				</DialogContent>
				<DialogActions>
					{/* <a href={`${applicant.resume}`} download> */}
						<Button  color="primary" onClick={e => setShowResume(true)}>
							Download resume
						</Button>
					{/* </a> */}
				</DialogActions>
				<DialogActions>
					<a href="!#" download>
						<Button color="primary">
							Download Degree certificate
						</Button>
					</a>
				</DialogActions>
				<DialogActions>
					<a href="!#" download>
						<Button color="primary">
							Download professional certificate
						</Button>
					</a>
				</DialogActions>
				<DialogActions>
					<a href="!#" download>
						<Button color="primary">
							Download cover letter
						</Button>
					</a>
				</DialogActions>
				</Dialog>
		</div>
	);
};

export default CustomizedDialogs;