import { createSlice } from '@reduxjs/toolkit';
import { baseUrl } from '../../../shared/baseUrl';
import { getToken } from '../../../Auth/getToken';

const initialState = {
  data: {

  },
  loading: false,
};

const jobDetailsSlice = createSlice({
  name: 'job',
  initialState,
  reducers: {
    getJobDetails: (state, actions) => {
      state.data = {...actions.payload}
    },
    isLoading: (state, actions) => {
      state.loading = actions.payload
    }
  }
});

export const { getJobDetails, isLoading } = jobDetailsSlice.actions;

export const fetchJobDetails = id => {
  return async dispatch => {
    dispatch(isLoading(true));
    const res = await fetch(`${baseUrl}/jobs/post/${id}`, {
      headers: {
        Authorization: `JWT ${getToken()}`
      }
    });

    const result = await res.json();

    if(result.success) {
      dispatch(getJobDetails(result.data));
      dispatch(isLoading(false));
      
    }
  }
};

export default jobDetailsSlice.reducer;