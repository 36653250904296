import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import makeStyles from '@material-ui/core/styles/makeStyles';
import 'react-lazy-load-image-component/src/effects/blur.css';
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  img: {
    height: props => props.height,
    width: '100%',
    // objectFit: "cover"
  }
}))

const Image = ({ image: {
  alt,
  height,
  src,
  width,
  caption,
  className,
} }) => {
  const classes = useStyles({ height });
  return (
    <LazyLoadImage
      alt={alt}
      height={height}
      src={src} // use normal <img> attributes as props
      width={width}
      className={clsx(classes.img, className)}
      effect="blur"
      style={{ width: width }}
    />
  )
};

export default Image;