import Button from '@material-ui/core/Button';
import React from 'react';
import TextFieldComponent from '../../../../../shared/inputs/TextFieldComponent';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import * as useAuth from '../hooks';

const useStyles = makeStyles(theme => ({
  link: {
    margin: '1rem 0',
    '& span': {
      marginLeft: '.5rem',
      color: theme.palette.primary.main,
      cursor: 'pointer'
    }
  },
  btn: {
    margin: '1rem 0'
  }
}))

const ForgotPassword = ({toggleActiveForm}) => {
  const classes = useStyles();
  const {
    errors,
    register,
    handleSubmit,
    onSubmit,
    disabled
  } = useAuth.useForgotPassword();
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h6" color="initial">Forgot Password</Typography>
      <TextFieldComponent
        name='email'
        label='Email address'
        type='email'
        inputRef={register}
        error={errors.email}
        helperText={errors.email?.message}
      />
      <Button disabled={disabled} color='primary' type='submit' fullWidth variant='contained' className={classes.btn}>Send Link</Button>
      <Typography variant="body1" color="initial" className={classes.link}>Go back to log in?<span onClick={toggleActiveForm('login')}>Log In</span></Typography>
    </form>
  )
}
export default ForgotPassword;