import makeStyles from '@material-ui/core/styles/makeStyles';
import ripple from '../assets/svgs/ripple.svg';

export const rippleImpact = makeStyles(theme => ({
  btn: {
    background: '#0E6BA6',
    position: 'relative',
    height: '3.75rem',
    minWidth: '13.75rem',
    color: '#fff',
    boxShadow: '0 0 0 1px #E0E8F7',
    margin: '20px 0',
    textTransform: 'uppercase',
    textDecoration: 'none',
    fontSize: '.8em',
    letterSpacing: '1.5px',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    borderRadius: '8px',
    outline: 'none !important',
    border: 'none',
    transition: '1s',
    '& div': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: '100',
      width: '100%'
    },
    '&:hover': {
      '& span': {
        width: '300%',
        height: '200%',
      }
      
    }
  },
  small_btn: {
    background: '#0E6BA6',
    position: 'relative',
    height: '2.8rem',
    minWidth: '7rem',
    color: '#fff',
    // boxShadow: '0 0 0 1px #E0E8F7',
    margin: '5px 0',
    textTransform: 'uppercase',
    textDecoration: 'none',
    fontSize: '.8em',
    letterSpacing: '1.5px',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    borderRadius: '8px',
    outline: 'none !important',
    border: 'none',
    transition: '1s',
    '& div': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: '100',
      width: '100%'
    },
    '&:hover': {
      '& span': {
        width: '300%',
        height: '200%',
      }
      
    }
  },
  span: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // animation: 'ripple 2.5s linear ',
    // borderRadius: '50%',
    pointerEvents: 'none',
    width: '0',
    height: '0',
    background: `url(${ripple}) no-repeat`,
    backgroundPosition: 'center',
    backgroundSize: '100% 100%',
    transition: '1s',
    // animation: `$ripple 2000ms ${theme.transitions.easing.easeInOut} infinite`
    
  },
  "@keyframes ripple": {
    "0%": {
      transform: 'scale(0)'
    },
    "100%": {
      transform: 'scale(1)'
    }
  },
  content: {
    zIndex: '100',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    fontWeight: 'bold'
  },
  press: {
    transition: '0.2s',
    transform: 'scale(0.9)',
  }
}));

export const visualStoryTellingStyle = makeStyles(theme => ({
  btn: {
    background: '#4777f4',
    position: 'relative',
    height: '3.75rem',
    width: '13.75rem',
    padding: '.5rem',
    fontWeight: 'bolder',
    color: '#fff',
    // boxShadow: '0 0 0 1px #E0E8F7',
    margin: '20px 0',
    textTransform: 'uppercase',
    textDecoration: 'none',
    fontSize: '.8em',
    letterSpacing: '1.5px',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    borderRadius: '8px',
    outline: 'none !important',
    border: 'none',
    '& svg': {
      color: '#fff',
    }
  },
}))