import Button from '@material-ui/core/Button';
import React from 'react';
import TextFieldComponent from '../../../../../shared/inputs/TextFieldComponent';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import * as useAuth from '../hooks';

const useStyles = makeStyles(theme => ({
  link: {
    margin: '1rem 0',
    '& span': {
      marginLeft: '.5rem',
      color: theme.palette.primary.main,
    }
  },
  btn: {
    margin: '1rem 0'
  }
}))

const ResetPassword = ({toggleActiveForm}) => {
  const classes = useStyles();
  const {
    errors,
    register,
    onSubmit,
    handleSubmit,
    disabled
  } = useAuth.useResetPassword(toggleActiveForm('login'));
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h6" color="initial">Reset Password</Typography>
      <TextFieldComponent
        name='password'
        label='Password'
        type='password'
        error={errors.password}
        helperText={errors.password?.message}
        inputRef={register}
      />
      <TextFieldComponent
        name='confirmPassword'
        label='Confirm Password'
        type='password'
        error={errors.confirmPassword}
        helperText={errors.confirmPassword?.message}
        inputRef={register}
      />
      <Button type='submit' disabled={disabled} color='primary' fullWidth variant='contained' className={classes.btn}>Reset Password</Button>
    </form>
  )
}
export default ResetPassword;