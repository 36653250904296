import React from 'react';
import Topbar from './components/Topbar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import RegistrationCard from './components/RegistrationCard';
import img from '../../../../assets/images/registration.jpg';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    width: '100%'
  },
  wrapper: {
    position: 'absolute',
    top: 60,
    left: 80,
    width: 400
  },
  topLayer: {
    background: '#fff',
    height: '40vh',
    position: 'relative'
  },
  img: {
    height: '100%',
    width: '100%'
  },
  typography: {
    width: 500,
    marginLeft: 120,
    // paddingTop: '20%'
    position: 'absolute',
    top: 80,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '.5rem',
      textAlign: 'center',
      margin: 0,
      position: 'static',
      fontSize: '2rem'
    }
  }
}));

const AdminRegistration = () => {
  const classes = useStyles();
  return (
    <main className={classes.root}>
      <Topbar />
      <div className={classes.topLayer}>
      <Typography variant="h4" color="initial" className={classes.typography}>At 5C, the word career means much more than a job. We understand the paramount need for our people to grow, and we make sure that this growth occurs. For recruitments within 5C or vacancies in our client companies, people are our most important assets and we ensure a conducive work environment with favorable conditions to enable their growth processes.</Typography>
      </div>
      <img src={img} alt="Banner img" className={classes.img}/>
        <RegistrationCard />
    </main>
  );
};

export default AdminRegistration;