/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import { makeStyles, Divider } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { useProfile, useAdminFunctions } from '../../../../hooks/useFetch';
import cogoToast from 'cogo-toast';
import { auth } from '../../../../utils/authContext';
import Loader from '../../../../utils/loader';
import logo from '../../../../assets/images/5C Logo.png';
import moment from 'moment';
import { HtmlParser } from '../../../../shared/htmlParser';
import { useSelector, useDispatch } from 'react-redux';
// import { DraftToHtml } from '../../../../hooks/useDraftToHtml';

const useStyles = makeStyles(theme => ({
  root: {
    lineHeight: '1.7',
    wordSpacing: 2,
    letterSpacing: .5,
    fontSize: '.95rem',
    width: '100%',
    '& h6': {
      margin: ' 1rem 0 2rem 0',
      fontWeight: 'bolder',
      color: '#0076c0',
      fontSize: '1.5rem'
    }
  },
  user_info: {
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    padding: '.7rem',
    width: '100%'
  },
  user_address: {
    padding: '.7rem'
  },
  border: {
    height: '100%',
    '& section': {
      marginTop: '.6rem'
    },
    borderRight: '1px solid rgba(0, 0, 0, 0.12)'
  },
  userInfo: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
  },
  section: {
    margin: '2rem 0'
  },
  career_info: {
    padding: '0'
  },
  pg : {
    marginTop: '.5rem',
    '& span': {
      fontWeight: 'bold',
      paddingRight: '.3rem',
      color: '#0076c0'
    }
  },
  bullet: {
    width: 10,
    height: 10,
    borderRadius: '50%',
    background: '#0076c0',
    marginRight: '.5rem'
  },
  box: {
    marginBottom: '3rem'
  },
  logo: {
    width: 100,
    height: 40,
    margin: '1rem auto'
  },
  footer: {
    textAlign: 'center',
    color: '#0076c0'
  },
  loader: {
    width: '100%',
    height: '50%',
    position: 'relative'
  }
}));

const formateDate = date => {
  const newDate = moment(date);
  return newDate.format('LL');
};

const matchJobs = (jobs, id) => {
  for(const i of jobs) {
    if(jobs.id === id) {
      return i;
    }
  };
};

const UserResume = ({data, type}) => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(true);
  const [careerInfo, setCareerInfo] = React.useState([]);
  const [setAuth] = React.useContext(auth);
  const [title, setTitle] = React.useState('');
  const [location, setLocation] = React.useState('');

  const desiredWorkLocation = useSelector(state => state.applicant.data.desiredWorkLocation)

  // const draftToHtml = useDraftToHtml();

  React.useEffect(() => {
    // const coverLetter = JSON.parse(data.userJobPosting.coverLetter);
    // console.log(convertFromRaw(coverLetter))
    const req = {
      auth: setAuth({type: 'get-token'}),
      id: type !== 'pool' ? data.userJobPosting.userId : data.id
    }
    if(type !== 'pool') {
      useAdminFunctions('job-details', {auth: setAuth({type: 'get-token'}), id: data.userJobPosting.jobPostingId}).then(data => {
        console.log(data);
        if(data.success) {
          setTitle(data.data.jobTitle);
          setLoading(false);
        }
      }).catch(e => {
        console.error(e);
        cogoToast.error('Service unavailable');
      })
    }
    
    useProfile('user-profile', req).then(data => {
      if(data.succes) {
        setCareerInfo(data.data);
        setLoading(false);
      }
    }).catch(e => {
      console.error(e);
      cogoToast.error('Service unavailable');
    })
  }, [data]);

  return (
    <div className={classes.root} >
      <div className={classes.logo}>
        <img src={logo} alt="5c_logo" style={{width: '100%', height: '100%'}}/>
      </div>
      <Grid container spacing={3} className={classes.user_address}>
        <Grid item lg={8} sm={12} md={12} xs={12}>
          <section>
            <h6>{`${data.firstName} ${data.lastName}`}</h6>
            {type !== 'pool' ? <h6>{title}</h6> : <></>}
          </section>
        </Grid>

        <Grid item lg={4} sm={12} md={12} xs={12}>
          <section>
            <p>{`${data.address} ${data.localGovAreaOfResidence}, ${data.stateOfResidence}`}</p>
            <p>{data.mobile}</p>
            <p>{data.email}</p>
            <p><a href={data.linkedIn} target="_blank" rel="noopener noreferrer">LinkedIn Profile: {data.linkedIn}</a></p>
            <p><a href={data.faceBook} target="_blank" rel="noopener noreferrer">Facebook Profile: {data.faceBook}</a></p>
          </section>
        </Grid>
      </Grid>

      <div className={classes.user_info}>
      <Grid container spacing={3}>
        <Grid item lg={6} sm={12} md={12} xs={12} className={classes.border}>
          <section className={classes.section}>
            <h6>Personal Informations</h6>
            <p className={classes.pg}><span>Marital Status:</span>{data.maritalStatus}</p>
            <p className={classes.pg}><span>Gender:</span>{data.gender}</p>
            <p className={classes.pg}><span>Date Of Birth:</span>{formateDate(data.dateOfBirth)}</p>
            <p className={classes.pg}><span>Location:</span>{data.stateOfResidence}</p>
            <p className={classes.pg}><span>Nationality:</span>{data.nationality}</p>
            <p className={classes.pg}><span>Language:</span>{data.languages}</p>
          </section>
          <Divider />
          <section>
            <h6>Skills</h6>
            {careerInfo.length !== 0 ? careerInfo.skill.map(item => {
              return (
                <section className={classes.section}>
                  <p className={classes.pg}><span>Name:</span>{item.name}</p>
                  <p className={classes.pg}><span>Proficiency:</span>{item.proficiency}</p>
                </section>
              )
            }): ''}
          </section>
          <Divider />
          <section className={classes.section}>
            {data.areaOfExpertise && <h6>Area Of expertise</h6>}
            {data.areaOfExpertise && data.areaOfExpertise.match(/,/g) ? data.areaOfExpertise.split(',').map(item => (
              <p><span className={classes.bullet}>.</span>{item}</p>
            )) : data.areaOfExpertise}
          </section>
          <Divider />
          <section className={classes.section}>
            <h6>Years of experience</h6>
            <p>{`${data.totalYearsOfExperience} Years`}</p>
          </section>
          <Divider />
          <section className={classes.section}>
            <h6>Current Salary</h6>
            <p>{data.currentSalary}</p>
          </section>
          <Divider />
          <section className={classes.section}>
            <h6>Expected Salary</h6>
            <p>{data.expectedSalary}</p>
          </section>
          <Divider />
          <section className={classes.section}>
            <h6>
              Availability
            </h6>
            <p>{data.availability}</p>
          </section>
          {data.desiredJobIndustry && 
            <>
              <Divider />
              <section className={classes.section}>
                <h6>Desired Job Industry</h6>
                {data.desiredJobIndustry.match(/,/) ? data.desiredJobIndustry.split(',').map(item => (
                  <p><span className={classes.bullet}>.</span>{item}</p>
                )) : data.desiredJobIndustry}
              </section>
            </>
          }
          <Divider />
          <section className={classes.section}>
            <h6>Desired Work Location</h6>
            {/* {data.desiredWorkLocation.match(/,/) ? data.desiredWorkLocation.split(',').map(item => (
              <p>{data.desiredWorkLocation}</p>
            )) : data.desiredWorkLocation} */}
            <p>{ desiredWorkLocation || data.desiredWorkLocation }</p>
          </section>
        </Grid>
        <Grid item lg={6} sm={12} md={12} xs={12} className={classes.career_info}>
          {type !== 'pool' ? <section className={classes.userInfo}>
            <h6>Career Summary</h6>
            <HtmlParser data={data.userJobPosting.coverLetter} />
          </section> : <></>}
          <section className={classes.userInfo}>
            <h6>Experience</h6>
            {careerInfo.length !== 0 ? careerInfo.workExperience.map(data => (
              <WorkExperienceSection data={data} key={data.id}/>
            )): ''}
          </section>
          <section className={classes.userInfo}>
            <h6>Education</h6>
            {careerInfo.length !== 0 ? careerInfo.educationalInfo.map(data => (
              <EducationSection data={data} key={data.id}/>
            )): ''}
          </section>
        </Grid>
      </Grid>
      </div>
      <div className={classes.footer}>
        <p>20A Babatunde Anjous Avenue, Off Admiralty Way, Lekki Penninsula Phase 1 Lagos – Lagos State</p>
        <p>Email: <a href="mailto:recruitment@5cee.com" target='_blank' rel="noopener noreferrer"> recruitment@5cee.com</a></p>
        <p>Website: <a href="https://www.5cee.com" target="_blank" rel="noopener noreferrer">www.5cee.com</a></p>
      </div>
    </div>
  );
};

const WorkExperienceSection = ({data}) => {
  const classes = useStyles()
  return (
    <div>
      <header className={classes.box}>
      <p className={classes.pg}><span style={{marginRight: '.3rem'}}>Company name: </span>{data.company}</p>
        <p className={classes.pg}>{`${formateDate(data.startDate)} - ${data.endDate === 'Current'?  data.endDate : formateDate(data.endDate)}`}</p>
        <p className={classes.pg}><span style={{marginRight: '.3rem'}}>Job title: </span>{data.jobTitle}</p>
        <HtmlParser data={data.jobDescription}/>
      </header>
    </div>
  );
};

const EducationSection = ({data}) => {
  const classes = useStyles()
  return (
    <Grid container spacing={1} className={classes.box}>
      <Grid lg={4} item>
        {data.institute}
      </Grid>
      <Grid lg={8} item>
        {`${formateDate(data.startDate)} - ${data.endDate === 'In view' ? data.endDate : formateDate(data.endDate)}`}
      </Grid>
      <Grid item lg={12}>
        <p className={classes.pg}><span style={{marginRight: '.3rem'}}>Field of study: </span>{data.fieldOfStudy}</p>
      </Grid>
      <Grid item lg={12}>
        <p className={classes.pg}><span style={{marginRight: '.3rem'}}>Qualification: </span>{data.qualification}</p>
      </Grid>
      <Grid item lg={12}>
        <p className={classes.pg}><span style={{marginRight: '.3rem'}}>Grade: </span>{data.grade}</p>
      </Grid>
    </Grid>
  );
};


export default UserResume;