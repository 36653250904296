/* eslint-disable react-hooks/exhaustive-deps */
/**
 * /* eslint-disable react-hooks/rules-of-hooks
 *
 * @format
 */

import React from 'react';
import SingleJob from '../../../../shared/singleJob';
import { auth } from '../../../../utils/authContext';
// import cogoToast from 'cogo-toast';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import '../../../../App.scss';
import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
// components
import Loader from '../../../../utils/loader';
import ScrollToTop from '../../../../utils/scrollToTop';
import { fetchJobDetails } from '../../job/jobDetailsSlice';
import Applicants from '../Applicants/applicants';

const useStyles = makeStyles(theme => ({
	root: {
		'& header': {
			background: 'none',
			boxShadow: 'none'
		}
	},
	tabs: {
		padding: 0,
		background: 'none'
	}
}));

function TabPanel(props) {
	const { children, value, index, ...other } = props;
	const classes = useStyles();

	return (
		<Typography
			component="div"
			role="tabpanel"
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && <Box p={3}>{children}</Box>}
		</Typography>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired
};

function a11yProps(index) {
	return {
		id: `full-width-tab-${index}`,
		'aria-controls': `full-width-tabpanel-${index}`
	};
}

const JobDetailsComponent = ({ match }) => {
	const [setAuth] = React.useContext(auth);
	const classes = useStyles();
	const theme = useTheme();
	const [value, setValue] = React.useState(0);
	const loading  = useSelector(state => state.job.loading);
  const dispatch = useDispatch();
	const jobDetails = useSelector(state => state.job.data);
  const [isApplied, setIsApplied] = React.useState(false);
  
	React.useEffect(() => {
		dispatch(fetchJobDetails(match.params.id));
      
	},[match.params.id]); //made changes here


	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const handleChangeIndex = index => {
		setValue(index);
	};

	if (loading || Object.entries(jobDetails).length === 0) {
		return <Loader />;
	}

	return (
		<div className={classes.root}>
			<ScrollToTop />
			<AppBar position="static" color="default">
				<Tabs
					value={value}
					onChange={handleChange}
					indicatorColor="primary"
					textColor="primary"
					aria-label="full width tabs example"
				>
					<Tab label="Job Details" {...a11yProps(0)} />
					<Tab label={`Applicants (${jobDetails.user.length})`} {...a11yProps(1)} />
				</Tabs>
			</AppBar>
			<SwipeableViews
				axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
				index={value}
				onChangeIndex={handleChangeIndex}
			>
				<TabPanel value={value} index={0} dir={theme.direction} className={classes.tabs}>
					<Grid container spacing={2}>
						<Grid item lg={12} md={12} sm={12} xs={12}>
							<SingleJob isApplied={isApplied} type='admin'/>
						</Grid>
					</Grid>
				</TabPanel>
				<TabPanel value={value} index={1} dir={theme.direction}>
					<Applicants users={jobDetails.user}/>
				</TabPanel>
			</SwipeableViews>
		</div>
	);
};

export default JobDetailsComponent;
