/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import AssignmentTurnedInRoundedIcon from '@material-ui/icons/AssignmentTurnedInRounded';
import jsPDF from 'jspdf';
import html2Canvas from 'html2canvas'
import UserResume from './userResume';
import { makeStyles, Avatar, IconButton, Grid, Tooltip } from '@material-ui/core';
import { useAdminFunctions } from '../../../../hooks/useFetch';
import cogoToast from 'cogo-toast';
import { auth } from '../../../../utils/authContext';
import Loader from '../../../../utils/loader';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '200vh',
    // width: '80%',
    padding: '0 !important',
    // margin: '0 !important',
    overflowX: 'hidden',
  },
  wrapper: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '100% !important',
    }
  },
  btn: {
    position: 'absolute !important',
    right: 30,
    zIndex: 1000,
    width: 'auto',
    top: 20,
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      right: 5,
    },
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      right: 5
    },
    [theme.breakpoints.down('md')]: {
      display: 'block',
      right: 5
    }
  },
  download: {
    background: '#0076c0',
    '& svg': {
      color: '#fff'
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'right'
    }
  },
  cancel: {
    background: '#f44336',
    '& svg': {
      color: '#fff'
    }
  },
  shortlist: {
    background: '#7ec365',
    '& svg': {
      color: '#fff'
    }
  },
  loader: {
    width: '50vw',
    height: '100vh',
  }
}));

const showFile = (blob, name) => {
  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  // var newBlob = new Blob([blob], {type: "application/pdf"})

  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob);
    return;
  } 

  // For other browsers: 
  // Create a link pointing to the ObjectURL containing the blob.
  const data = window.URL.createObjectURL(blob);
  var link = document.createElement('a');
  link.href = data;
  link.download=`${name}.pdf`;
  link.click();
  setTimeout(function(){
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(data);
  }, 100);
}
export default function ResumeDialog({data, open, setOpen, shortList, type}) {
  const classes = useStyles();
  const [scroll] = React.useState('body');
  const [setAuth] = React.useContext(auth);
  const resumeName = `${data.firstName}-${data.lastName}_Resume`;
  // get current origin
	const [origin] = React.useState(window.location.host);

  const handleClose = () => {
    setOpen(false);
  };

  const elementHTML = React.useRef(null);
  const [loading, setLoading] = React.useState(false);

  const saveAsPdf = () => {
    setLoading(true);
    const req = {
      auth: setAuth({ type: 'get-token' }),
      id: data.userJobPosting.jobPostingId,
      body: {
        userId: data.id
      }
    };
    fetch(`https://fivec.herokuapp.com/api/v1/auth/user/build-resume/${req.id}`, {
      method: 'POST',
      headers: {
        Authorization: `JWT ${req.auth}`,
        'Content-type': 'application/json'
      },
      body: JSON.stringify(req.body)
    }).then(res => res.json()).then(data => {
      if(data.success) {
        setLoading(false);
        fetch(`https://fivec.herokuapp.com/api/v1/auth/user/download-resume/${req.body.userId}`, {
          headers: {
            authorization: setAuth({type: 'get-token'})
          },
        }).then(res => res.blob()).then(data => showFile(data, resumeName));
      }
    })
  }

  const handleShortlist = () => {
    const req = {
      auth: setAuth({ type: 'get-token' }),
      id: data.userJobPosting.jobPostingId,
      origin,
      body: {
        userId: data.userJobPosting.userId
      }
    };

    useAdminFunctions('short-list', req).then(data => {
      const toast = cogoToast.loading('Shortlisting applicant....');
      toast.then(() => {
        if (data.success) {
          cogoToast.success(data.message);
        } else {
          cogoToast.error('Something went wrong');
        }
      });
    }).catch(e => {
      console.error(e);
      cogoToast.error('Service unavailable');
    });
  }


  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        className={classes.wrapper}
      >
       {!loading ? <><Grid container spacing={4} className={classes.btn}>
        {type !== 'pool' ? 
          <>
            <Grid item lg={4} sm={12} md={12} xs={12}>
              <DownloadBtn handleDownload={saveAsPdf}/>
            </Grid>
            <Grid item lg={4} sm={12} md={12} xs={12}>
              <ShortListBtn handleShortlist={handleShortlist}/>
            </Grid> 
          </>
          : <></>}
        <Grid item lg={4} sm={12} md={12} xs={12}>
          <CancelBtn handleCancel={handleClose}/>
        </Grid>
      </Grid>
        <DialogContent dividers={scroll === 'paper'} ref={elementHTML} className={classes.root}>
          <UserResume data={data} type={type}/>
        </DialogContent></> : <div className={classes.loader}><Loader /></div>}
      </Dialog>
    </div>
  );
};

const DownloadBtn = ({handleDownload}) => {
  const classes = useStyles();
  return (
    <Avatar className={classes.download}>
      <Tooltip title='Download Resume' placement='top'>
        <IconButton onClick={handleDownload}>
          <GetAppRoundedIcon />
        </IconButton>
      </Tooltip>
    </Avatar>
  );
};

const CancelBtn = ({handleCancel}) => {
  const classes = useStyles();
  return (
    <Avatar className={classes.cancel}>
      <Tooltip title='Cancel' placement='top'>
        <IconButton onClick={handleCancel}>
          <ClearRoundedIcon />
        </IconButton>
      </Tooltip>
    </Avatar>
  );
};

const ShortListBtn = ({handleShortlist}) => {
  const classes = useStyles();
  return (
    <Avatar className={classes.shortlist}>
      <Tooltip title='Shortlist Applicant' placement='top'>
        <IconButton onClick={handleShortlist}>
          <AssignmentTurnedInRoundedIcon />
        </IconButton>
      </Tooltip>
    </Avatar>
  );
};
