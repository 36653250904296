import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import logo from '../../../../../assets/images/5C Logo.png';
import { Link } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles(theme => ({
  root: {
    background: '#fff',
    padding: '2rem 0'
  },
  wrapper: {
    margin: '0 120px',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      margin: 0
    }
  },
  logo: {
    width: 150,
    height: 70,
    marginLeft: '.5rem'
  }
}));

const Topbar = () => {
  const classes = useStyles();
  return (
    <nav className={classes.root}>
      <section className={classes.wrapper}>
        <Link to='/'>
          <IconButton>
            <HomeRoundedIcon color='primary' fontSize='large'/>
          </IconButton>
        </Link>
      <img src={logo} alt="logo" className={classes.logo}/>
      </section>
      
    </nav>
  );
};

export default Topbar;