import { createSlice } from '@reduxjs/toolkit';
import { getToken } from '../../Auth/getToken';

const initialState = {
  data: {}
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    getProfile(state, actions) {
      state.data = actions.payload
    }
  },
});

export const { getProfile } = profileSlice.actions;

export const fetchUserProfile = user => {
  return async dispatch => {
    const url = user === 'admin' ? `https://fivec.herokuapp.com/api/v1/auth/admin/${getToken(true)}` : `https://fivec.herokuapp.com/api/v1/auth/user/${getToken(true)}`;
    
    try {
      const res = await fetch(url, {
        headers: {
          'Authorization': `JWT ${getToken()}`
        }
      });
      const result = await res.json();
      return dispatch(getProfile(result.data))
    } catch (e) {
      console.error(e);
    }
    
  }
}

export default profileSlice.reducer;