import React from 'react';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Image from './Image';

const useStyles = makeStyles(theme => ({
  root: {
    // ,
    width: '100%',
    paddingTop: 85,
    height: 400,
    [theme.breakpoints.down('sm')]: {
      paddingTop: '4rem',
      height: 'auto'
    }
  },
  item: {
    height: '100%',
    padding: 0,
    background: theme.palette.primary.main,
    '& img': {
      objectFit: 'cover'
    },
    [theme.breakpoints.down('sm')]: {
      height: '40vh'
    }
  }
}))

const SharedBanner = ({
  Content,
  img,
  short,
  style
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root} style={style}>
      <Grid container style={{ height: '100%' }}>
        <Grid item lg={8} md={6} sm={12} xs={12} className={classes.item}>
          {Content}
        </Grid>
        <Grid item lg={4} md={6} sm={12} xs={12} className={classes.item}>
          <Image
            image={{
              alt: 'Banner image',
              height: '100%',
              width: '100%',
              src: img,
              objectFit: "cover"
            }}
          />
        </Grid>
      </Grid>
    </div >
  );
};

export default SharedBanner;