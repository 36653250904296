
export const getToken = id => {
  const token = localStorage.getItem('auth-state');
  if(id) {
    const id = JSON.parse(token);
    if(id) {
      return id.id;
    }
    
  } else {
    return JSON.parse(token).token;
  }
};