/** @format */

import React from 'react';
import { rippleImpact, visualStoryTellingStyle } from '../styles/animatedBtn';
import makeStyles from '@material-ui/core/styles/makeStyles';
import anime from 'animejs';
import NearMeRoundedIcon from '@material-ui/icons/NearMeRounded';
import DirectionsRunRoundedIcon from '@material-ui/icons/DirectionsRunRounded';
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles(theme => ({
  section: {
    opacity: '0',
    // display: 'none'
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  icon: {
    opacity: '0',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  content: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  }
}));

export const RippleImpactBtn = ({ content, onClick, type, submit, color }) => {
  const classes = rippleImpact() ;
  const ripple = React.useRef(null);
  const ripples = React.useRef(null);
  const btn = React.useRef(null);

  
  const handleEvent = e => {
    // add classes to the button
    // btn.current.classList.add(`${classes.press}`);
    // setTimeout(() => btn.current.classList.remove(`${classes.press}`), 500);
    if(onClick)  onClick();
   
  }
	return (
		<>
			<button ref={btn} className={!type ? classes.btn : classes.small_btn} onClick={handleEvent} type={submit}>
				<div>{content}</div>
				<span className={classes.span} ref={ripple}></span>
			</button>
		</>
	);
};

const animateBtn = ref => {
  const timeline = anime.timeline({
    easing: 'easeOutExpo',
    duration: 750
  });
  timeline.add({
    targets: ref.text,
    opacity: 0,
    display: 'none',
  })
  timeline.add({
    targets: ref.btn,
    height: '50px',
    width: '50px',
    borderRadius: '50%',
    padding: '.5rem'
  })
  timeline.add({
    targets: ref.icon,
    translateX: [0, 75],
    opacity: [1, 0]
  }, '-=650')
  timeline.add({
    targets: ref.icon2,
    opacity: [0, 1],
    left: [
      { value: '50%', duration: 400 },
      { value: '15%', duration: 350 }
    ],
  })
  timeline.add({
    targets: ref.btn,
    height: '3.75rem',
    minWidth: '13.75rem',
    borderRadius: '8px',
  }, '-= 350')
  timeline.add({
    targets: ref.text2,
    // opacity: 0
    opacity: [0, 1],
  })
  timeline.add({
    targets: ref.btn,
    backgroundColor: '#32CD32'
  }, '-= 750')
};

export const VisualStoryTellingBtn = ({setRedirect}) => {
  const classes_2 = useStyles();
  const classes = visualStoryTellingStyle();
  const [click, setClick] = React.useState(false);
  const btn = React.useRef(null);
  const text = React.useRef(null);
  const text2 = React.useRef(null);
  const icon = React.useRef(null);
  const icon2 = React.useRef(null);
  const handleClick = () => {
    setTimeout(() => setClick(true),500)
    const ref = {
      btn: btn.current,
      text: text.current,
      text2: text2.current,
      icon: icon.current,
      icon2: icon2.current
    }
    if(!click) animateBtn(ref);

    setTimeout(() => setRedirect(true), 3500);
  };

  return (
    <button className={classes.btn} onClick={handleClick} ref={btn}>
      <Grid container alignItems='center'>
        <Grid item lg={2} sm={2} md={2} xs={2}>
          <NearMeRoundedIcon fontSize='large' ref={icon}/>
        </Grid>
        <Grid item lg={10} sm={10} md={10} xs={10} ref={text}>
          FIND OUT MORE
        </Grid>
      </Grid>
      <Grid container alignItems='center'>
        <Grid item lg={2} sm={2} md={2} xs={2}>
          <DirectionsRunRoundedIcon fontSize='large' ref={icon2} className={classes_2.icon}/>
        </Grid>
        <Grid item lg={10} sm={10} md={10} xs={10} ref={text2} className={classes_2.section}>
         LET'S GO
        </Grid>
      </Grid>
    </button>
  );
};
