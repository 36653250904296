/** @format */

import React from 'react';

export const auth = React.createContext();
const AuthProvider = props => {
	const setAuth = data => {
		switch (data.type) {
			case 'get-token':
				if (localStorage.getItem('auth-state')) {
					const token = localStorage.getItem('auth-state');
					return JSON.parse(token).token;
				} else {
					return '';
				}
			case 'get-role':
				if (localStorage.getItem('auth-state')) {
					const role = localStorage.getItem('auth-state');
					return JSON.parse(role).role;
				}
				break;
			case 'get-id':
				if (localStorage.getItem('auth-state')) {
					const id = localStorage.getItem('auth-state');
					return JSON.parse(id).id;
				}
				break;
			case 'set':
				if (localStorage.getItem('auth-state')) {
					localStorage.removeItem('auth-state');
					localStorage.setItem('auth-state', JSON.stringify(data.data));
				}
				localStorage.setItem('auth-state', JSON.stringify(data.data));
				break;
			case 'delete':
				localStorage.removeItem('auth-state');
				break;
			default:
				return data;
		}
	};

	return <auth.Provider value={[setAuth]}>{props.children}</auth.Provider>;
};

export default AuthProvider;
