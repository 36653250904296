import Button from '@material-ui/core/Button';
import React from 'react';
import TextFieldComponent from '../../../../../shared/inputs/TextFieldComponent';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import * as  useAuth from '../hooks';

const useStyles = makeStyles(theme => ({
  link: {
    margin: '1rem 0',
    '& span': {
      marginLeft: '.5rem',
      color: theme.palette.primary.main,
      cursor: 'pointer'
    }
  },
  btn: {
    margin: '1rem 0'
  }
}))


const Login = (props) => {
  const { toggleActiveForm } = props;
  const classes = useStyles();
  const {
    errors,
    handleSubmit,
    register,
    onSubmit,
    disabled
  } = useAuth.useLogin(props);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h6" color="initial">Login</Typography>
      <TextFieldComponent
        name='email'
        label='Email address'
        type='email'
        inputRef={register}
        error={errors.email}
        helperText={errors.email?.message}
      />
      <TextFieldComponent
        name='password'
        label='Password'
        type='password'
        inputRef={register}
        error={errors.password}
        helperText={errors.password?.message}
      />
      <Typography variant="body1" color="initial" className={classes.link}>Forgot password?<span onClick={toggleActiveForm('forgotPassword')}>Here</span></Typography>
      <Button type='submit' disabled={disabled} color='primary' fullWidth variant='contained' className={classes.btn}>Login</Button>
      <Typography variant="body1" color="initial" className={classes.link}>Don't have an account?<span onClick={toggleActiveForm('signup')}>Sign Up</span></Typography>
    </form>
  )
}
export default Login;