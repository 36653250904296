import makeStyles from '@material-ui/core/styles/makeStyles';
import ripple from '../../assets/svgs/rippleSvg.svg';
import React from 'react';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  btn: {
    background: '#FACF5A',
    position: 'relative',
    height: '3.75rem',
    minWidth: '13.75rem',
    margin: '20px 0',
    boxShadow: '0px 2px 2px 0px rgba(0,0,0,0.24), 0px 0px 2px 0px rgba(0,0,0,0.12)',
    textTransform: 'uppercase',
    textDecoration: 'none',
    fontSize: '.8em',
    letterSpacing: '1.5px',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    borderRadius: '2px',
    outline: 'none !important',
    border: 'none',
    transition: '1s',
    '& div': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: '100',
      width: '100%',
      fontWeight: 'bold'
    },
    '&:hover': {
      '& span': {
        width: '300%',
        height: '200%',
      }
      
    }
  },
  small_btn: {
    background: '#FACF5A',
    position: 'relative',
    height: '2.2rem',
    minWidth: '7rem',
    boxShadow: '0px 2px 2px 0px rgba(0,0,0,0.24), 0px 0px 2px 0px rgba(0,0,0,0.12)',
    margin: '5px 0',
    textTransform: 'uppercase',
    textDecoration: 'none',
    fontSize: '.8em',
    letterSpacing: '1.5px',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    borderRadius: '2px',
    outline: 'none !important',
    border: 'none',
    transition: '1s',
    '& div': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: '100',
      width: '100%',
      fontWeight: 'bold'
    },
    '&:hover': {
      '& span': {
        width: '300%',
        height: '200%',
      }
      
    }
  },
  span: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // animation: 'ripple 2.5s linear ',
    // borderRadius: '50%',
    pointerEvents: 'none',
    width: '0',
    height: '0',
    background: `url(${ripple}) no-repeat`,
    backgroundPosition: 'center',
    backgroundSize: '100% 100%',
    transition: '1s',
    // animation: `$ripple 2000ms ${theme.transitions.easing.easeInOut} infinite`
    
  },
  "@keyframes ripple": {
    "0%": {
      transform: 'scale(0)'
    },
    "100%": {
      transform: 'scale(1)'
    }
  },
  content: {
    zIndex: '100',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    fontWeight: 'bold'
  },
  press: {
    transition: '0.2s',
    transform: 'scale(0.9)',
  }
}));

const AnimBtn = ({
  size,
  children,
  onClick,
  type,
  className
}) => {
  const classes = useStyles();
  return (
		<>
			<button className={clsx(className, {
        [classes.btn]: !size,
        [classes.small_btn]: size
      })} onClick={onClick && onClick} type={type && 'submit'}>
				<div>{children}</div>
				<span className={classes.span}></span>
			</button>
		</>
	);
};

export default AnimBtn;