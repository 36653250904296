import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CustomCard from '../customCard';
import Quote from '../quote';
import ListNumber from '../ListNumber';
import { RippleImpactBtn } from '../../shared/animatedBtn';
import { heading } from '../../styles/headings';
import delivery from '../../assets/images/delivery.jpg'

const useStyles = makeStyles(theme => ({
  root: {
    background: '#fff',
    position: 'relative',
    bottom: -20,
  },
  landing_page: {
    background: '#8effb2',
    paddingTop: '10rem',
    position: 'relative',
    height: '110vh',
    [theme.breakpoints.up('xl')]: {
      height: '60vh',
    },
    '& header': {
      '& h1': {
        textAlign: 'center',
        color: '#111'
      }
    }
  },
  content: {
    width: 700,
    margin: '1rem auto',
    [theme.breakpoints.down('md')]: {
      width: '80%',
    },
    '& p': {
      textAlign: 'center',
      color: '#111'
    }
  },
  img: {
    width: 900,
    position: 'absolute',
    // bottom: '-40%',
    left: '50%',
    transform: 'translateX(-50%)',
    // boxShadow: '0 32px 54px rgba(0, 0, 0, 0.13)'
  },
  wrapper: {
    width: 1000,
    margin: '15rem auto 1rem auto',
  },
  wrapper_content: {
    width: 600,
    margin: '.5rem auto',
    '& p': {
      textAlign: 'center',
    }
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      margin: '.5rem auto'
    }
  },
  header: {
    margin: '2rem 0',
    textAlign: 'center',
  }
}));

const ourPackage = [
  {
    title: 'Instant Delivery',
    text: 'This delivery model allows you to place a delivery request within the hours of 8am-8pm and have it delivered without delay.'
  },
  {
    title: 'At your Service',
    text: 'Our at Your Service model requires that requests and payments are made before 7:30am of the scheduled delivery date and we guarantee delivery the “same day”.'
  },
  {
    title: 'Have It Your Way',
    text: 'Our Have It Your Way model allows you to make delivery requests customized to your specific business or individual need.'
  },
];

const deliveries = [
  {text: 'Parcel Pickup and Delivery'},
  {text: 'Next Day Delivery'},
  {text: 'Pick Up Service'},
  {text: 'Food Delivery'},
];

const DeliveryComponent = () => {
  const classes = useStyles();
  const headings = heading();
  return (
    <div className={classes.root}>
      <LandingPage />
      <section className={classes.wrapper}>
        <div className={classes.wrapper_content}>
          <p className={headings.paragraphy_1}>From freight forwarding, haulage services, mail room services, warehousing and distribution services, and e-commerce logistics, we’ll deliver it better – just how you want it</p>
        </div>
        <div className={classes.header}>
          <h2 className={headings.heading_2}>Our Packages</h2>
        </div>
        <section className={classes.flex} >
          {ourPackage.map(item => (
            <CustomCard key={item.title} title={item.title} text={item.text} />
          ))}
        </section>

<section>
        <div className={classes.service}>
          <h2 className={headings.heading_2}>We are happy to help you with:</h2>
          {
                deliveries.map((delivery, i) => {
                  return (
                      <ListNumber
                        key={i}
                        number={i + 1}
                        text={delivery.text}
                      />
                  )
                })
              }
              <RippleImpactBtn content='Contact us' className={classes.button} to='/contact_us' />
        </div>
      </section>
      </section>
    </div>
  );
};

const LandingPage = () => {
  const classes = useStyles();
  const headings = heading();

  return (
    <div className={classes.landing_page}>
      <header>
        <h1 className={headings.heading_1}>The Delivery</h1>
      </header>
      <Quote quote='Your trusted Logistics Partner...' quote2='We are ready to CARRY GO!' height='200px'/>
      <div className={classes.content}>
        <p className={headings.paragraphy_1}>We handle your logistics in getting what you want where you want it, and at the right time. Our delivery service is led by an exceptional team of technical and strategic talents, with knowledge of the local environment to ensure speedy response to pick up, swift and secure delivery, and at the best rates.</p>
      </div>

      <img className={classes.img} src={delivery} alt="hero_image"/>
    </div>
  );
}

export default DeliveryComponent;