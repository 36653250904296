/** @format */

import React, { Suspense, lazy } from 'react';
import { Switch, BrowserRouter as Router, Route } from 'react-router-dom';
import Loader from './loader';
import DeliveryComponent from '../components/our_services_page/DeliveryComponent';
import JobDetailsComponent from '../components/portal/admin/job-details/JobDetailsComponent';
import Registration from '../components/portal/applicant/authentication/Registration';
import AdminRegistration from '../components/portal/admin/authentication/AdminRegistration';
import ScrollToTop from './scrollToTop';

const ConciergeServices = lazy(() => import('../components/our_services_page/concierge/ConciergeServices'));
const FacilityManagement = lazy(() => import('../components/our_services_page/concierge/FacilityManagement'));
const TheDelivery = lazy(() => import('../components/our_services_page/concierge/TheDelivery'));
const FiveCHubOnline = lazy(() => import('../components/our_services_page/concierge/5cHubOnline'));

const Pages = lazy(() => import('./pages'));
const ApplicantConfirm = lazy(() => import('../components/portal/applicant/confirm'));
const AdminConfirm = lazy(() => import('../components/portal/admin/adminConfirmation'));
const PrivateRoute = lazy(() => import('./privateRoute'));
const CompleteBio = lazy(() => import('../components/portal/applicant/bio/completeBio'));
const JobDetails = lazy(() => import('../components/portal/job/jobDetails'));
const HomePage = lazy(() => import('../components/Home-page/homePage'));
const SignUp = lazy(() => import('../components/portal/applicant/signUp'));
const ForgetPassword = lazy(() => import('../components/portal/applicant/forgetPassword'));
const AdminSignUp = lazy(() => import('../components/portal/admin/signUp'));
const AdminForgotPassword = lazy(() => import('../components/portal/admin/forgotPassword'));
const AdminResetPassword = lazy(() => import('../components/portal/admin/resetPassword'));
const BioData = lazy(() => import('../components/portal/applicant/bio/profile'));
const Document = lazy(() => import('../components/portal/forms/document'));
const Education = lazy(() => import('../components/portal/applicant/education/education'));
const FilesUpload = lazy(() => import('../components/portal/forms/filesUpload'));
const Skills = lazy(() => import('../components/portal/applicant/skills/userSkills'));
const WorkExperience = lazy(() => import('../components/portal/applicant/work-experience/workExp'));
const UserJobListing = lazy(() => import('../components/portal/applicant/job-listing/jobListing'));
const UserFavouriteJobs = lazy(() => import('../components/portal/applicant/favourite-jobs/favouriteJobs'));
const PostJob = lazy(() => import('../components/portal/admin/post-job/postJob'));
const Test = lazy(() => import('../components/portal/dashboard/dashboard'));
const JobListing = lazy(() => import('../components/portal/admin/job-listing/job'));
const Bookmark = lazy(() => import('../components/portal/admin/bookmark-list/bookmark'));
const Settings = lazy(() => import('../components/portal/admin/settings/settings'));
const EditJob = lazy(() => import('../components/portal/admin/post-job/editJob'));
const Applicants = lazy(() => import('../components/portal/admin/Applicants/applicants'));
const AdminDashboard = lazy(() => import('../components/portal/admin/admin-dashboard/dashboard'));
const UserDashboard = lazy(() => import('../components/portal/applicant/dashboard/userDashboard'));
const Dialog = lazy(() => import('../components/portal/admin/settings/dialog'));
const CompanyProfile = lazy(() => import('../components/companyProfile/CompanyProfile'));
// const OurTeamPage = lazy(() => import('../components/about_us_page/our_team_page/ourTeam'));
const PersonnalService = lazy(() => import('../components/our_services_page/personnalService/PersonelService'));
const RecruitmentServices = lazy(() => import('../components/our_services_page/personnalService/RecruitmentServices'));
const HumanResources = lazy(() => import('../components/our_services_page/personnalService/HumanResources'));
const FinanceServices = lazy(() => import('../components/our_services_page/financeAndSourcing/FinanceAndSourcing'));
const PayrollSolutions = lazy(() => import('../components/our_services_page/financeAndSourcing/PayrollSolutions'));
const TheConcierge = lazy(() => import('../components/our_services_page/concierge/TheConcierge'));
const CommingSoon = lazy(() => import('../components/our_services_page/comingSoon'));
const facilityManagement = lazy(() => import('../components/our_services_page/facilityManagement'));
const facilityHub = lazy(() => import('../components/our_services_page/facilityHub'));
const UserInfo = lazy(() => import('../components/portal/admin/settings/profileSettings'));
const manageAdmin = lazy(() => import('../components/portal/admin/settings/manageAdmin'));
const ContactUsPage = lazy(() => import('../components/contact_us/contactUs'));
const Careers = lazy(() => import('../components/careers/Careers'));
const ExecutiveManagement = lazy(() => import('../components/our_services_page/executiveManagement/ExecutiveManagement'))
const ExpatriateLifestyleManagement = lazy(() => import('../components/our_services_page/executiveManagement/ExpatriateLifestyleManagement'));;

const Routes = () => {
	return (
		<Suspense fallback={<Loader />}>
			<Router>
				<ScrollToTop>
				<Switch>
					<Pages exact path='/' component={HomePage} />
					{/* <Pages exact path='/our_team' component={OurTeamPage} /> */}
					<Pages exact path='/company_profile' component={CompanyProfile} />
					<Pages exact path='/personnel_service' component={PersonnalService} />
					<Pages exact path='/personnel_service/recruitment_services' component={RecruitmentServices} />
					<Pages exact path='/personnel_service/human_resources&functional_outsourcing' component={HumanResources} />
					<Pages exact path='/finance_service' component={FinanceServices} />
					<Pages exact path='/finance_service/payroll_solutions' component={PayrollSolutions} />
					<Pages exact path='/coming_soon' component={CommingSoon} />
					<Pages exact path='/concierge_service' component={TheConcierge} />

					{/* sub concierge services */}
					<Pages exact path='/concierge_service/services' component={ConciergeServices} />
					<Pages exact path='/concierge_service/facility_management' component={FacilityManagement} />
					<Pages exact path='/concierge_service/5c_hub_online' component={FiveCHubOnline} />
					<Pages exact path='/concierge_service/the_delivery' component={TheDelivery} />

					<Pages exact path='/facility_management' component={facilityManagement} />
					<Pages exact path='/facility_hub' component={facilityHub} />
					<Pages exact path='/the_delivery' component={DeliveryComponent} />
					<Pages exact path='/executive_management' component={ExecutiveManagement} />
					<Pages exact path='/executive_management/expatriate_lifestyle_management' component={ExpatriateLifestyleManagement} />
					<Pages exact path='/contact_us' component={ContactUsPage} />
					<Pages exact path='/careers' component={Careers} />
					<Route exact path='/portal/signup' component={SignUp} />
					<Pages exact path='/portal/login' component={Registration} />
					<Route exact path="/dialog" component={Dialog} />
					<Route exact path="/portal/forgot_password" component={ForgetPassword} />
					<Route exact path="/portal/reset_password/:id" component={Registration} />
					<Route exact path="/portal/admin/login" component={AdminRegistration} />
					<Route exact path="/portal/admin/signup" component={AdminSignUp} />
					<Route exact path="/portal/admin/forgot_password" component={AdminForgotPassword} />
					{/* to be corrected */}
					<Route exact path="/passwordreset/:id" component={AdminResetPassword} />
					<Route exact path='/portal/login/:id' component={ApplicantConfirm} />
					<Route exact path='/login/:id' component={AdminConfirm} />
					<Route exact path="/job" component={Test} />

					{/* private route */}
					<PrivateRoute
						exact
						path="/portal/job_listing"
						component={UserJobListing}
						role="user"
						redirect="/portal/login"
					/>
					<PrivateRoute
						exact
						path="/portal/favourite_jobs"
						component={UserFavouriteJobs}
						role="user"
						redirect="/portal/login"
					/>
					<PrivateRoute
						exact
						path="/portal/dashboard"
						component={UserDashboard}
						role="user"
						redirect="/portal/login"
					/>
					<PrivateRoute
						exact
						path="/portal/job_listing/:id"
						component={JobDetails}
						role="user"
						redirect="/portal/login"
					/>
					<PrivateRoute
						exact
						path="/portal/bio_data"
						component={BioData}
						role="user"
						redirect="/portal/login"
					/>
					<PrivateRoute
						exact
						path="/portal/bio_data/complete_bio"
						component={CompleteBio}
						role="user"
						redirect="/portal/login"
					/>
					<PrivateRoute
						exact
						path="/portal/education"
						component={Education}
						role="user"
						redirect="/portal/login"
					/>
					<PrivateRoute
						exact
						path="/portal/work_experience"
						component={WorkExperience}
						role="user"
						redirect="/portal/login"
					/>
					<PrivateRoute
						exact
						path="/portal/skills"
						component={Skills}
						role="user"
						redirect="/portal/login"
					/>
					<PrivateRoute
						exact
						path="/portal/document"
						component={Document}
						role="user"
						redirect="/portal/login"
					/>
					<PrivateRoute
						exact path="/portal/files_upload"
						component={FilesUpload}
						role="user"
						redirect="/portal/login"
					/>
					{/* admin private routes */}
					<PrivateRoute
						exact path="/portal/admin/post_job"
						component={PostJob}
						role="admin"
						redirect="/portal/admin/login"
					/>
					<PrivateRoute
						exact path="/portal/admin/dashboard"
						component={AdminDashboard}
						role="admin"
						redirect="/portal/admin/login"
					/>
					<PrivateRoute
						exact path="/portal/admin/applicants/:id"
						component={Applicants}
						role="admin"
						redirect="/portal/admin/login"
					/>
					<PrivateRoute
						exact path="/portal/admin/job/:id"
						component={JobDetailsComponent}
						role="admin"
						redirect="/portal/admin/login"
					/>
					<PrivateRoute
						exact path='/portal/admin/settings'
						component={Settings}
						role="admin"
						redirect="/portal/admin/login"
					/>
					<PrivateRoute
						exact path='/portal/admin/settings/profile'
						component={UserInfo}
						role="admin"
						redirect="/portal/admin/login"
					/>
					<PrivateRoute
						exact path='/portal/admin/settings/manage_admin'
						component={manageAdmin}
						role="admin"
						redirect="/portal/admin/login"
					/>
					<PrivateRoute
						exact path='/portal/admin/bookmark'
						component={Bookmark}
						role="admin"
						redirect="/portal/admin/login"
					/>
					<PrivateRoute
						exact path='/portal/admin/job'
						component={JobListing}
						role="admin"
						redirect="/portal/admin/login"
					/>
					<PrivateRoute
						exact path="/portal/admin/edit_job/:id"
						component={EditJob}
						role="admin"
						redirect="/portal/admin/login"
					/>

				</Switch>
				</ScrollToTop>
			</Router>
		</Suspense>
	);
};

export default Routes;
