/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useContext} from 'react'
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import UpdateOutlinedIcon from '@material-ui/icons/UpdateOutlined';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import SchoolOutlinedIcon from '@material-ui/icons/SchoolOutlined';
import Buttons from '@material-ui/core/Button';
import checkedSvg from '../../../../assets/svgs/checked.svg';
import { Accordion, Card, Button } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import DialogDetails from './dialogDetails';
import CreatePool from './create';
import cogoToast from 'cogo-toast';
import { useAdminFunctions } from '../../../../hooks/useFetch';
import { auth } from '../../../../utils/authContext';
import ResumeDialog from './resumeDialog';
import SharedDialog from '../../../../shared/sharedDialog';
import { RippleImpactBtn } from '../../../../shared/animatedBtn';
import { fetchJobDetails } from '../../job/jobDetailsSlice';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

const useStyles = makeStyles((theme) => ({
  accoWrapper: {
    margin: '1%',
    [theme.breakpoints.down('xs')]: {
      margin: '2%',
    }
  },
  container1: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'normal'
    }
  },
  container2: {
    display: 'flex',
    /* align-items: center; */
    justifyContent: 'space-between',
    flexDirection: 'column',
    // width: '70%',
    [theme.breakpoints.down('xs')]: {
    },
    '& span' : {
      marginRight: '1rem'
    }
  },
  container3: {
    // width: '500px',
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.only('md')]: {
      flexDirection: 'column',
      width: '200px',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      width: '100%',
    },
  },
  trim: {
    // width: '410px',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
  },
  trim2: {
    // width: '200px',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
  },
  trim3: {
    // width: '200px',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
  },
  container4: {
    display: 'flex',
    width: '400px',
    justifyContent: 'space-between',
    [theme.breakpoints.only('md')]: {
      marginTop: '.5rem',
      width: '100%',
      justifyContent: 'space-between',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '.5rem',
      width: '100%',
      justifyContent: 'space-between',
    },
  },
  bookmark: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  boomarkText: {
    marginTop: '1%'
  },
  pg: {
    padding: '.7rem 0',
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.2)'
    }
  }
}))

const ApplicantCard = ({ applicant, jobId, setReload }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [showResume, setShowResume] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [name, setName] = React.useState('');
  const [text, setText] = useState(applicant.userJobPosting.status);
  const [setAuth] = useContext(auth);
  const [dialogFor, setDialogFor] = React.useState('');
  const [data, setData] = React.useState([]);
  const [otherCert, setOtherCert] = React.useState([]);

  const dispatch = useDispatch();

  const { id } = useParams();

  // get current origin
	const [origin] = React.useState(window.location.host);
  React.useEffect(() => {
    fetch(`https://fivec.herokuapp.com/api/v1/auth/user/uploadDocument/${applicant.id}`, {
      headers: {
        Authorization: `JWT ${setAuth({type: 'get-token'})}`
      }
    }).then(res => res.json()).then(data => {
      if(data.success) {
        setOtherCert(data.userDocs);
      }
    }).catch(e => console.log(data));

  }, [applicant])
  const handleInput = (e) => {
    setName(e.target.value);
  };

  const handleShow = () => {
    setShow(!show);
  };

  const shortList = (id) => {
    const req = {
      auth: setAuth({ type: 'get-token' }),
      id: jobId,
      origin,
      body: {
        userId: id
      }
    };

    useAdminFunctions('short-list', req).then(data => {
      const toast = cogoToast.loading('Shortlisting applicant....');
      toast.then(() => {
        // refetch data when shortlist action runs
        // setReload(true);
        console.log(data);
        if(data.success && data.message === 'Application Does not exist') {
          cogoToast.warn('Applicant already shortlisted');
        }
        else if (data.success) {
          cogoToast.success(data.message);
          setText('shortlisted');
        } else {
          cogoToast.error('Something went wrong');
        }
      });
    }).catch(e => {
      console.error(e);
      cogoToast.error('Service unavailable');
    });
  };

  const handleDeleteApplicant = (userId) => {
    const req = {
      auth: setAuth({ type: 'get-token' }),
      id: jobId,
      origin,
      body: {
        userId
      }
    };

    useAdminFunctions('delete-applicant', req)
      .then(data => {
        const toast = cogoToast.loading('deleting applicant from list....');
        toast.then(() => {
          if (data.success) {
            cogoToast.success(data.message);
            // refetch data when delete action runs
            // setReload(true);
            dispatch(fetchJobDetails(id));
          } else {
            cogoToast.error('Something went wrong');
          }
        });
      })
      .catch(e => {
        console.error(e);
        cogoToast.error('Service unavailable');
      });
  };

  // return an array of data
  const returnArray = data => {
    return !data ? [] : data.split();
  };


  return (
    <div className={classes.accoWrapper}>
      <Accordion>
        <Card>
          <Card.Header>
            <div className={classes.container1}>
              <div className={classes.container2}>
                <h4>{`${applicant.firstName} ${applicant.lastName}`}</h4>
                <div className={classes.container3}>
                  <span className={classes.trim}>
                    <img src={checkedSvg} alt="checked square" />
                    {applicant.desiredJobIndustry !== null ? applicant.desiredJobIndustry : 'not specified'}
                  </span>
                  <span className={classes.trim2}>
                    <LocationOnOutlinedIcon color="error" />
                    {applicant.stateOfResidence !== null ? applicant.stateOfResidence : 'not specified'}
                  </span>
                  <span className={classes.trim3}><UpdateOutlinedIcon color="error" />
                    {`${applicant.totalYearsOfExperience}`} years experience
                  </span>
                </div>

              </div>
              <div className={classes.container4}>
                <RippleImpactBtn content='Delete' type='small' onClick={e => handleDeleteApplicant(applicant.id)} />
                <RippleImpactBtn content='Details' type='small' onClick={e => setShowResume(!showResume)} />
                <RippleImpactBtn content={text === 'shortlisted' ? `Shortlisted` : `Shortlist`} type='small' onClick={e => (shortList(applicant.id))} />
              </div>
            </div>
            <div className={classes.bookmark}>
              <Accordion.Toggle as={Button} variant="link" eventKey="0">
                <AddCircleRoundedIcon />
              </Accordion.Toggle>
              <CreatePool handleInput={handleInput} applicantId={applicant.id} handleShow={handleShow} show={show} setShow={setShow} />
            </div>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
                <p style={{cursor: 'pointer'}} className={classes.pg}><a href={applicant.userJobPosting.resume} target='_blank' rel='noopener noreferrer' download><GetAppOutlinedIcon color="primary" /> View Applicant Resume</a></p>
              <p style={{cursor: 'pointer'}} className={classes.pg} onClick={e => {
                setOpenDialog(true);
                setDialogFor('Professional certificate');
                setData(returnArray(applicant.professionalCert));
              }
                } ><GetAppOutlinedIcon color="primary" /> Download Professional certificate</p>
              
              <p style={{cursor: 'pointer'}} className={classes.pg} onClick={e => {
                setOpenDialog(true);
                setData(otherCert)
                setDialogFor('Other certificate');
                }}><GetAppOutlinedIcon color="primary" /> Download Other certificate</p>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
        <DialogDetails open={open} applicant={applicant} setOpen={setOpen} setShowResume={setShowResume}/>
        <ResumeDialog data={applicant} open={showResume} setOpen={setShowResume}/>
        <SharedDialog open={openDialog} setOpen={setOpenDialog} name={dialogFor} data={data}/>
    </div>
  );
};

export default ApplicantCard;