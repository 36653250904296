import React from 'react';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { heading } from '../styles/headings';
import { blue } from '@material-ui/core/colors';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  number: {
    fontWeight: 'bolder',
    fontSize: 17,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 50,
    height: 50,
    color: '#111',
    background: '#fff',
    boxShadow: '0px 0px 14px rgba(0, 0, 0, 0.12), 0px 14px 14px rgba(0, 0, 0, 0.237602)',
    float: 'right',
    [theme.breakpoints.down('sm')]: {
      height: 35,
      width: 35
    }
  },
  icon: {
    fontWeight: 'bolder',
    fontSize: 17,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 50,
    height: 50,
    border: '3px solid rgba(45, 49, 54, 0.09)',
    transition: '.3s ease-in-out',
    // background: '#3f48cc',
    color: '#3f48cc',
  },
  root: {
    '& p': {
      fontSize: '16.5px !important'
    }
  },
  text: {
    textAlign: 'justify'
  }
}));

const ListNumber = (props) => {
  const classes = useStyles();
  const headings = heading();
 return (
  <Grid container spacing={3} alignItems='center' className={classes.root}>
    <Grid item xl={!props.type ? 1 : 2} lg={!props.type ? 1 : 2} md={!props.type ? 1 : 2} sm={2} xs={2}>
      {!props.type ? <div className={classes.number} id='number'>
        {props.number}
      </div> : <Icon className={classes.icon} id='icon'>{props.icon}</Icon>}
    </Grid>
    <Grid item xl={!props.type ? 11 : 10} lg={!props.type ? 11 : 10} md={!props.type ? 11 : 10} sm={10} xs={10}>
      <Typography variant="body1" color="initial" id='sfProLight' className={classes.text}>{props.text}</Typography>
    </Grid>
  </Grid>
 );
};

export default ListNumber;