import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { heading } from '../styles/headings';

const useStyles = makeStyles(theme => ({
  card: {
    borderTop: '#4777f4',
    borderTopWidth: '7px',
    borderTopStyle: 'solid',
    width: 200,
    textAlign: '-webkit-auto',
    padding: '1.2rem 0',
    [theme.breakpoints.down('sm')]: {
			width: '100%',
		}
  }
}))

const CustomCard = props => {
  const classes = useStyles(props);
  const headings = heading()

  return (
    <div className={classes.card}>
      <div>
        <h5 className={headings.heading_2}>{props.title}</h5>
        <p className={headings.paragraphy_1}>{props.text}</p>
        {props.text2 ? <p className={headings.paragraphy_1}>{props.text2}</p> : <></>}
      </div>
    </div>
  );
};

export default CustomCard;